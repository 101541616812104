// Created By: artur

import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-select-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export interface IOptions {
  value: string|number;
  label: string;
}

export class AuroraSelect extends LitElement {
  @property({ type: Boolean })
  private _open = false;
  
  @property({ type: Object })
  data: {
    placeholder?: string;
    value?: string|number;
    options: IOptions[];
  }

  static get styles() {
    return [styles, global, typography];
  }

  onClickOutside = (event: any) => {
    if (!this._open) return;
    if (this.shadowRoot?.contains(event.target)) return;
    if (event.composedPath().includes(this)) return;
    this._open = null;
  };

  onScroll = (evt: Event) => {
    // check if target is the component and stop
    if (evt.composedPath().includes(this)) return;
    
    if (!this._open) return;
    this._open = null;
  };

  selectedText() {
    const { value, options, placeholder } = this.data ?? {};

    if (!value) return placeholder ?? '';

    const selectedOption = options.find(option => option.value === value);

    return selectedOption?.label ?? '';
  }

  toggleOptions() {
    this._open = !this._open;
  }

  onClickOption(option: IOptions) {
    this.dispatchEvent(new CustomEvent('value', {
      detail: option.value,
      bubbles: true,
      composed: true,
    }));

    this._open = false;
  }

  async connectedCallback() {
    super.connectedCallback();

    document.addEventListener('click', this.onClickOutside);
    document.body.addEventListener('scroll', this.onScroll)
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this.onClickOutside);
    document.body.removeEventListener('scroll', this.onScroll);
  }

  render() {
    const { value, options } = this.data ?? {};

    return html`
      <label class="aurora-select">
        <div class="aurora-select_container" @click=${() => this.toggleOptions()}>
          <div 
              class="${classMap({
                'aurora-select_value': true,
                'has-value': !!value,
              })}"
              >
            ${this.selectedText()}
          </div>

          <div class="aurora-select_after">
            <div 
              class="${classMap({
                'aurora-select_arrow-icon': true,
                active: this._open,
              })}"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 8L12 15L19 8" stroke="#C81E82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div>

        <div
          class="${classMap({
            'aurora-select_options': true,
            open: this._open,
            close: !this._open,
          })}"
        >
          ${options?.map(option => html`
            <div 
              class="${classMap({
                'aurora-select_option': true,
                'choosed': value === option.value,
              })}"
              @click=${() => this.onClickOption(option)}
            >
              ${option.label}
            </div>
          `)}
        </div>
      </label>
    `
  }
}
