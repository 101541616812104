// Created By: artur

import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';

// styles
import styles from './aurora-side-nav-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';
import { classMap } from 'lit/directives/class-map.js';

export class AuroraSideNav extends LitElement {
  @property({ type: Boolean })
  _rendered = false;

  @property({ type: Object })
  data: {
    open?: boolean;
    title?: string;
    showBackBtn?: boolean;
    showDownside?: boolean
  }
  
  @state() _loading: boolean;

  static get styles() {
    return [styles, global, typography];
  }

  close() {
    this.dispatchEvent(new CustomEvent('close', { bubbles: true, composed: true }));
  }

  backBtn() {
    this.dispatchEvent(new CustomEvent('back', { bubbles: true, composed: true }));
  }

  connectedCallback() {
    super.connectedCallback();
  }

  firstUpdated() {
    this._rendered = true
  }

  shouldUpdate(changedProperties: Map<string | number | symbol, unknown>) {
    // This will prevent the update of the component if the only property that changed is the _rendered
    // This is because of the animation that is being used to open and close the side nav

    const changedData: any = changedProperties.get('data')
    
    if (
      !this.data.open
      && (
        // eslint-disable-next-line no-undefined
        changedData?.open !== undefined
        && this.data.open === changedData?.open
      )
    ) return false;

    if (changedProperties.size === 1 && changedProperties.has('_rendered')) {
      return false;
    }

    return true;
  }
  render() {
    const { open = false, title = 'Menu', showBackBtn = false } = this.data ?? {};

    return html`
      <div class="${classMap({
        'aurora-side-nav': true,
        'open': open,
        'closed': !open && this._rendered
      })}">
        <div class="aurora-side-nav-container">
          <div class="title-container">
            <div class="title">
              ${showBackBtn ? html`
                <button class="back-btn" @click="${this.backBtn}">
                  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1L1 8L8 15" stroke="#2B2C2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              ` : nothing}
            
              ${title}
            </div>

            <button class="close-btn" @click="${this.close}">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="26" height="26" fill="white"/>
            <path d="M6.02405 4.80933C5.38945 4.17472 4.36055 4.17472 3.72595 4.80933C3.09135 5.44393 3.09135 6.47282 3.72595 7.10742L10.17 13.5515L3.87699 19.8446C3.24239 20.4792 3.24239 21.5081 3.87699 22.1427C4.51159 22.7773 5.54049 22.7773 6.17509 22.1427L12.4681 15.8496L18.7612 22.1426C19.3958 22.7772 20.4247 22.7772 21.0593 22.1426C21.6939 21.508 21.6939 20.4791 21.0593 19.8445L14.7662 13.5515L21.2103 7.10744C21.8449 6.47284 21.8449 5.44395 21.2103 4.80935C20.5757 4.17474 19.5468 4.17474 18.9122 4.80935L12.4681 11.2534L6.02405 4.80933Z" fill="#2B2C2D"/>
            </svg>
            
            </button>
          </div>

          <div class="menu-container">
            <div class="upperside">
              <slot name="upperside"></slot>
            </div>
            ${this.data.showBackBtn ? nothing : html`
              <div class="downside">
                <slot name="downside" id="downside"></slot>
              </div>
            `}
          </div>
      </div>
  
      <div class="${classMap({
        'aurora-side-nav-backdrop': true,
        'open': open
      })}" @click="${this.close}"></div>
    `;
  }
}
