// Created By: Victor Tommasi

import { LitElement, PropertyValueMap, html } from 'lit';
import { property, query, queryAssignedElements, state } from 'lit/decorators.js';

// styles
import styles from './aurora-base-carousel-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';
import { classMap } from 'lit/directives/class-map.js';

export type AuroraBaseCarouselProps = {
  direction?: 'vertical' | 'horizontal',
  gutters?: boolean,
  edgeBounce?: boolean,
}

const DESKTOP_WIDTH = 992;

export class AuroraBaseCarousel extends LitElement {
  @property({ type: Object }) data: AuroraBaseCarouselProps;
  @state() private _isDesktop: boolean;
  @state() private _currentIndex: number;
  @state() private _offset: number;
  @state() private _currentEdgeSpace: number;
  private _start: number;
  private _isDown: boolean;
  private _isDragging: boolean;
  private _carouselHeight: number;

  @queryAssignedElements({ flatten: true })
  childrenElements!: Array<HTMLElement>;

  constructor() {
    super();
    this.data = {
      direction: 'horizontal',
      gutters: true,
      edgeBounce: true,
    }
    this._isDesktop = window.innerWidth > DESKTOP_WIDTH;
    this._currentIndex = 0;
    this._offset = 0;
    this._currentEdgeSpace = 0;
    this._start = null;
    this._isDown = false;
    this._isDragging = false;
    this._carouselHeight = 0;
  }

  @query('#carousel-container') private _carouselContainer!: HTMLElement;

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('resize', this._updateIsDesktop.bind(this));
    // We want to listen for a custom event called "resetCarousel" that will reset the carousel to its initial state.
    this.addEventListener('resetCarousel', () => this.resetCarousel());
    // We use "this" to add an event listener to this auroraBaseCarousel instance.
    // This means that the movePrevious, moveNext events are being listened for the auroraBaseCarousel component itself.
    this.addEventListener('movePrevious', () => this.move('previous'));
    this.addEventListener('moveNext', () => this.move('next'));
    this.addEventListener('updateCarousel', this.updateCarousel);
  }
  
  disconnectedCallback() {
    super.disconnectedCallback();
    
    window.removeEventListener('resize', this._updateIsDesktop.bind(this));
    this.removeEventListener('resetCarousel', () => this.resetCarousel());
    this.removeEventListener('movePrevious', () => this.move('previous'));
    this.removeEventListener('moveNext', () => this.move('next'));
    this.removeEventListener('updateCarousel', this.updateCarousel);
  }

  private resetCarousel() {
    this._currentIndex = 0;
    this._offset = 0;
    this._currentEdgeSpace = 0;
    this.style.setProperty('--item-offset', `${this._offset}px`);
  }

  private updateCarousel(e: CustomEvent) {
    this._currentIndex = e.detail.newIndex;
    this.animateTransition();
  }

  private _updateIsDesktop() {
    this._isDesktop = window.innerWidth > DESKTOP_WIDTH;
  }

  static get styles() {
    return [styles, global, typography];
  }

  get numberOfChildren() {
    return this.childrenElements.length;
  }

  get itemDimension() {
    if (this.data?.direction === 'vertical') return (this.childrenElements[0] as HTMLElement)?.offsetHeight;
    return (this.childrenElements[0] as HTMLElement)?.offsetWidth;
  }

  get itemMargin() {
    if (!this.data?.gutters) return 0;
    const styles = getComputedStyle(this);
    return parseFloat(styles.getPropertyValue('--item-margin'));
  }

  get itemTotalDimension() {
    return this.itemDimension + this.itemMargin;
  }

  get maxOffset() {
    return this.itemTotalDimension * this.numberOfChildren;
  }

  get containerDimension() {
    return (this.data?.direction === 'vertical')
      ? this._carouselHeight
      : this._carouselContainer?.clientWidth;
  }

  get lastIndex() {
    const itemsPerPage = Math.floor(this.containerDimension / this.itemTotalDimension) || 1;
    return (this.numberOfChildren - itemsPerPage);
  }

  get edgeBounds() {
    return (this.itemTotalDimension * this.numberOfChildren) - this.containerDimension;
  }

  get edgeSpace() {
    if (this.containerDimension < this.itemTotalDimension) return 0;
    return (this.containerDimension % this.itemTotalDimension) + this.itemMargin;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.updated(_changedProperties);

    this.dispatchEvent(new CustomEvent('carousel-initialized', {
      detail: { 
        isCarouselDisabled: this.maxOffset <= this.containerDimension
      }
    }));
    
    if (_changedProperties.has('_offset')) {
      if (this._offset === 0) this._currentIndex = 0;
      if (this._offset === this.edgeBounds) this._currentIndex = this.lastIndex;
    }

    if (this.parentElement) {
      this._carouselHeight = this.parentElement.clientHeight;
    }

    if (_changedProperties.has('_currentIndex')) {
      this.dispatchEvent(new CustomEvent('sync-values', {
        detail: {
          currentIndex: this._currentIndex,
          offset: this._offset,
          isCarouselEnd: this._currentIndex === this.lastIndex,
        }
      }));
    }
  }

  render() {
    const { direction, gutters } = this.data;

    return html`
      <section class="aurora-base-carousel">
        <div
          id="carousel-container"
          role="tablist"
          tabindex="0"
          class=${classMap({ 
            'carousel-container': true, 
            [direction]: direction ?? 'horizontal', 
            gutters: gutters ?? false,
            'disabled': this.maxOffset <= this.containerDimension,
          })}
          aria-label="hero carousel"
          @mousedown=${this.handleDragStart}
          @mousemove=${this.handleDragMove}
          @mouseup=${this.handleDragEnd}
          @mouseleave=${this.handleDragEnd}
          @touchstart=${this.handleDragStart}
          @touchmove=${this.handleDragMove}
          @touchend=${this.handleDragEnd}
          @keydown="${(e: KeyboardEvent) => this.handleTabKeyDown(e)}"
        >
          <slot @slotchange=${() => this.requestUpdate()}></slot>
        </div>
      </section>
    `;
  }

  private handleTabKeyDown(e: KeyboardEvent): void {
    if (e.key === 'ArrowRight') this.move('next')
    if (e.key === 'ArrowLeft') this.move('previous')
  }

  /**
   * Animate the transition between slides.
   * Handles cases where the current index is at the maximum index and adjusts the offset accordingly.
   */
  private animateTransition(): void {
    // If current index is at maximum index and offset is less than right limit, switch to previous slide
    if ((this._currentIndex === this.lastIndex) && (this._offset + this.itemDimension < this.edgeBounds)) {
      this.prevSlide();
      this._offset = this._currentIndex * this.itemTotalDimension;
      this._currentEdgeSpace = 0;
    } else {
      // Calculate offset based on current index and whether it's at the maximum index
      if (this._currentIndex === this.lastIndex) {
        this._offset = this._currentIndex * this.itemTotalDimension - this.edgeSpace;
        this._currentEdgeSpace = this.edgeSpace;
      } else {
        this._offset = this._currentIndex * this.itemTotalDimension;
        this._currentEdgeSpace = 0;
      }
    }

    // Reset startX for next transition
    this._start = null;
    // Apply the calculated offset as a CSS variable
    this.style.setProperty('--item-offset', `${this._offset}px`);
  }
  
  /**
   * Handle the start of a drag event (mouse press or touch start).
   * Sets initial drag state variables and disables transition effects during drag.
   * @param e MouseEvent or TouchEvent representing the start of the drag event.
   */
  private handleDragStart(e: MouseEvent | TouchEvent): void {
    // Prevent default browser behavior if it's a desktop event
    if (this._isDesktop) e.preventDefault();

    // Record the initial X position of the drag event
    this._start = this.getEvent(e);

    // Indicate that the mouse/touch is down, i.e., the drag has started
    this._isDown = true;

    // Disable transition effects during drag by setting transition property to none
    this._carouselContainer.style.transition = 'none';
  }

  /**
   * Handle the movement of the slide when dragged by the user.
   * Calculates the offset based on the drag distance and limits the offset within certain bounds.
   * @param e MouseEvent or TouchEvent representing the drag event.
   */
  private handleDragMove(e: MouseEvent | TouchEvent): void {
    // If drag hasn't started or mouse is not down, exit
    if (this._start === null || !this._isDown) return;

    // Indicate that dragging is in progress
    this._isDragging = true;

    e.preventDefault();

    // Get the current axis position of the drag event
    const currentAxis = this.getEvent(e);

    // Calculate the horizontal distance dragged since the drag started
    const diffAxis = currentAxis - this._start;

    // Define the dragging limit on the edges of the carousel
    const draggingBoundsOnEdges = this.data.edgeBounce ? 40 : 0;

    // Calculate the new offset based on the drag distance
    this._offset = (this._currentIndex * this.itemTotalDimension) - diffAxis - this._currentEdgeSpace;

    // Calculate the maximum offset based on the container dimension and dragging limit on edges
    const maxOffset = (this.maxOffset - this.containerDimension) + draggingBoundsOnEdges;

    // Bounds the offset to prevent the slide from moving beyond certain boundaries
    if (this._offset <= 0) this._offset = -draggingBoundsOnEdges;
    if (this._offset >= maxOffset) this._offset = maxOffset;

    // Apply the calculated offset as a CSS variable to move the carousel
    this.style.setProperty('--item-offset', `${this._offset}px`);
  }

  /**
   * Handle the end of a drag event (mouse release or touch end).
   * Adjusts the slide position based on the drag distance and initiates a transition animation.
   * @param e MouseEvent or TouchEvent representing the end of the drag event.
   */
  private handleDragEnd(e: MouseEvent | TouchEvent): void {
    // Prevent default browser behavior if it's a desktop event
    if (this._isDesktop) e.preventDefault();
    
    // If drag hasn't started or mouse is not down, exit
    if (this._start === null || !this._isDown) return;

    // If dragging occurred during the drag event, add/remove click event listener to prevent unintentional clicks
    if (this._isDragging) {
      this._carouselContainer.addEventListener('click', this.preventClick);
    } else {
      this._carouselContainer.removeEventListener('click', this.preventClick);
    }

    // Reset drag state variables
    this._isDown = false;
    this._isDragging = false;

    // Calculate the horizontal distance dragged since the drag started
    const diffAxis = this.getEvent(e) - this._start;

    // Define heaviness to determine how much drag is required to change slides
    // The lower the number, the more you have to drag to change slides
    const heaviness = 10; 

    // If the drag distance is significant and current index allows, move to the previous slide
    if ((diffAxis > this.itemDimension / heaviness) && (this._currentIndex > 0)) {
      // Calculate the number of steps to move based on drag distance
      const steps = Math.round(diffAxis / this.itemTotalDimension);
      this.prevSlide(steps);
    } 
    
    // If the drag distance is significant and current index allows, move to the next slide
    if ((diffAxis < -this.itemDimension / heaviness) && (this._currentIndex < this.lastIndex)) {
      // Calculate the number of steps to move based on drag distance
      const steps = Math.round(Math.abs(diffAxis / this.itemTotalDimension));
      this.nextSlide(steps);
    }

    // Initiate a transition animation to adjust the slide position
    this.animateTransition();
  }

  private getEvent(e: MouseEvent | TouchEvent): number {
    if (this.data?.direction === 'vertical') {
      return e instanceof MouseEvent ? e.clientY: e.changedTouches[0]?.clientY;
    } else {
      return e instanceof MouseEvent ? e.clientX : e.changedTouches[0]?.clientX;
    }
  }

  nextSlide(steps = 1): void {
    const numSteps = steps || 1;

    if (this._currentIndex + numSteps > this.lastIndex) {
      this._currentIndex = this.lastIndex;
      return;
    }

    this._currentIndex = (this._currentIndex + numSteps);
  }

  prevSlide(steps = 1): void {
    const numSteps = steps > this._currentIndex ? this._currentIndex : steps || 1;
    this._currentIndex = (this._currentIndex - numSteps + this.numberOfChildren) % this.numberOfChildren;
  }

  preventClick(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   * Move the carousel in the specified direction (previous or next) by adjusting the offset.
   * If moving left, it checks if moving is possible without going beyond the left limit,
   * and updates the offset accordingly. If moving right, it checks if moving is possible
   * without going beyond the right limit, and updates the offset accordingly.
   * @param direction Direction in which to move the carousel ('previous' or 'next').
   */
  move(direction: 'previous' | 'next'): void {
    // If moving left
    if (direction === 'previous') {
      // Check if moving is possible without going beyond the left limit
      if (this._offset - this.itemTotalDimension >= 0) {
        // Update the offset to move left by one item dimension
        this._offset = this._offset - this.itemTotalDimension;
        // Move to the previous slide
        this.prevSlide();
      } else {
        // Set the offset to 0 if moving left is not possible without going beyond the left limit
        this._offset = 0;
      }
    } else { // If moving right
      // Check if moving is possible without going beyond the right limit
      if (this._offset + this.itemTotalDimension > this.edgeBounds) {
        // Set the offset to the right limit if moving right is not possible without going beyond it
        this._offset = this.edgeBounds;
      } else {
        // Update the offset to move right by one item dimension
        this._offset = this._offset + this.itemTotalDimension;
        // Move to the next slide
        this.nextSlide();
      }
    }

    // Apply the updated offset as a CSS variable to move the carousel
    this.style.setProperty('--item-offset', `${this._offset}px`);
  }
}