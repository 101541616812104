// Created By: 

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { domPurify } from '../../utils/domPurify';

// styles
import styles from './aurora-nav-header-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

// Constants
import { novantHealthUrl } from '../../constants';
import imageIsFullPath from '../../utils/imageIsFullPath';

import '../../aurora-navbar/index';
import '../../aurora-side-nav/index';
import '../../aurora-nav-item/index';
import '../../aurora-side-nav-item/index';

export type Croppings = [
  {
    imageSrc: string;
    srcSet: string;
    contentType: 'Image';
  },
];

type OriginalImage = Pick<Image, 'contentType' | 'imageSrc' | 'altText'>;

export type Image = {
  contentType: 'Image' | 'Svg' | 'ResponsiveImage';
  imageSrc: string;
  altText: string;
  croppings?: Croppings;
  original?: OriginalImage;
};

export type Styles = string[];

export interface NavigationPanels {
  columnContent: [
    {
      sectionTitle: string;
      menuListContent: [
        {
          title: string;
          url: string;
          openInNewWindow: boolean;
        }
      ];
    }
  ];
}

const WANT_TO = 'I Want To...';

export interface IHeaderData {
  language?: string;
  displayOption?: string;
  logoUrl?: string;
  image?: Image;
  headerStyle?: Styles;
  globalStyle?: Styles;
  tagline?: string;
  navigationItems: [{ contentType?: string }];
  utilityNavigation?: [{
    title: string;
    url: string;
    openInNewWindow: boolean;
    image: Image;
    navigationPanels?: NavigationPanels[];
  }];
  id?: number;
  skipNavigation?: string;
}

export interface NavHeaderData {
  headerdata?: IHeaderData;
}

export class AuroraNavHeader extends LitElement {
  @property({ type: Boolean, attribute: false })
  private _debug = false;

  @property({ type: Boolean, attribute: false })
  private _loading = true;

  @property({ type: String, attribute: false })
  private _megaMenuOpen = '';

  @property({ type: Object, attribute: false })
  private _activeSubnav: any = null;

  @property({ type: Boolean, attribute: false })
  private _sideOpen = false;

  @property({ type: Object, attribute: false })
  private _internalData: NavHeaderData = null;

  @property({ type: String, attribute: false })
  private _sideTitle = 'Menu';

  @property({ type: Boolean, attribute: false })
  private _sideShowBackBtn = false;

  @property({ type: Promise, attribute: false })
  private _promise: Promise<any> = null;

  @property({ type: Object })
  data: NavHeaderData = null;

  @property({ type: Boolean, attribute: 'hide' }) _hide = false;

  static get styles() {
    return [styles, global, typography];
  }

  // check in each loop update this.data 
  updated(changedProperties: Map<string | number | symbol, unknown>) {
    if (changedProperties.has('data')) {
      this.fetchDefaultData(`${novantHealthUrl}/api/navigation/header`);
    }
  }

  onClickOutside = (event: any) => {
    if (!this._megaMenuOpen) return;
    if (this.shadowRoot?.contains(event.target)) return;
    if (event.composedPath().includes(this)) return;
    this._megaMenuOpen = null;
  };

  onHitEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && this._sideOpen) this.close();
  };

  onScroll = () => {
    if (!this._megaMenuOpen) return;
    this._megaMenuOpen = null;
  };

  async connectedCallback() {
    super.connectedCallback();

    // check query params for 'hide-aurora-header' and set _hide to true if found
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('hide-aurora-header')) {
      this._hide = true;
    }

    await this.updateComplete;
    document.addEventListener('click', this.onClickOutside);
    document.body.addEventListener('scroll', this.onScroll)
    document.addEventListener('keydown', this.onHitEscape);
    
    this.fetchDefaultData(`${novantHealthUrl}/api/navigation/header`);
  }
  
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this.onClickOutside);
    document.body.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('keydown', this.onHitEscape);
  }

  private async fetchDefaultData(url: string) {
    if (this.data?.headerdata) {
      this._loading = false;
      this._internalData = this.data
    } else {
      // This avoids multiple parallel requests
      if (!this._promise)  {
        this._loading = true;
        this._promise = fetch(url)
          .then((response) => response.json())
          .then((json) => {
            this._internalData = { headerdata: json.header };
            this._loading = false;
          });
      }

      await this._promise;
    }

    this.subnavBack();

    this.requestUpdate();
  }

  subnavClickHandler(item: any) {
    if (!item.navigationPanels?.length) return;

    this._sideTitle = item.title;
    this._sideShowBackBtn = true;
    this._activeSubnav = item;
  }

  subnavBack() {
    const wantToMenu = this._internalData.headerdata?.utilityNavigation?.find((item: any) => item.title === WANT_TO) || null
    this._sideTitle = document.body.clientWidth > 992 ?  wantToMenu?.title || 'Menu' : 'Menu';
    this._sideShowBackBtn = false;
    this._activeSubnav = null;
  }

  close() {
    this._sideOpen = false;
    this.subnavBack();
  }

  private toggleMegaMenu(item: any) {
    if (!item.navigationPanels?.length) {
      this._megaMenuOpen = null;
    } else {
      this._megaMenuOpen = this._megaMenuOpen === item.title ? '' : item.title;
    }
  }

  render() {
    if (this._loading) return html`<div class="aurora-nav-header"></div>`;
    if (this._hide || (!Object.keys(this._internalData?.headerdata).length)) return nothing;

    const debug = new URLSearchParams(location.search).has('_should-debug');
    const { headerdata } = this._internalData;
    const { logoUrl, image, utilityNavigation, navigationItems } = headerdata;

    const wantToMenu = utilityNavigation.find((item: any) => item.title === WANT_TO)
    const wantToMenus = (wantToMenu?.navigationPanels || [])[0]?.columnContent;
    const myNovantBtnData: any = utilityNavigation?.find((item: any) => item?.title === 'MyNovant') || null;

    return html`
      <aurora-navbar class="aurora-nav-header">
        <div class="aurora-nav-header__logo" slot="logo">
          <a href=${logoUrl} title=${image?.altText} >
            ${image?.contentType === 'Image'
              ? html`<img
                src=${imageIsFullPath(image?.imageSrc)
                  ? image.imageSrc
                  : novantHealthUrl + image?.imageSrc}
                alt=${image?.altText} />`
              : html`<slot .innerHTML=${domPurify(image?.imageSrc)} name="logo"></slot>`
            }
          </a>
        </div>

        ${navigationItems.map((item: any) => html`
          <aurora-nav-item 
            @click="${() => this.toggleMegaMenu(item)}"
            @keydown="${(event: KeyboardEvent) => {
              if (event.key === 'Enter') this.toggleMegaMenu(item);
            }}"
            slot="nav"
            .data=${{
              label: item.title,
              link: item.navigationPanels?.length ? null : {
                href: item.url,
                target: item.openInNewWindow ? '_blank' : '_self',
              },
              active: item.active || this._megaMenuOpen === item.title,
              megamenuOpen: this._megaMenuOpen === item.title,
              megamenu:  !item.navigationPanels?.length ? null :  {
                children: item.navigationPanels?.map((panel: any) => panel.columnContent.map((column: any) => ({
                  title: column.sectionTitle,
                  options: column.menuListContent.map((link: any) => ({
                    label: link.title,
                    link: {
                      href: link.url,
                      target: link.openInNewWindow ? '_blank' : '_self',
                    }
                  }))
                })
                )).flat()
              }
            }}
          ></aurora-nav-item>
        `)}

        <div class="aurora-nav-header__right" slot="right">
          <div>
            ${!myNovantBtnData?.title ? nothing : html`
              <a
                href="${myNovantBtnData.url}"
                class="aurora-nav-header__mynovant-btn"
              >
                <span class="aurora-nav-header__my-novant">
                  ${myNovantBtnData.title}
                </span>
                <slot .innerHTML=${domPurify(myNovantBtnData?.image?.imageSrc)}></slot>
               
              </a>
            `}
          </div>

          <div class="aurora-nav-header__v-separator"></div>
           
          <div>
            <button class="aurora-nav-header__sidenav-btn" @click="${() => this._sideOpen = true}">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="aurora-nav-header__desktop-icon">
                <rect x="6" y="6" width="4" height="4" rx="1" fill="#53565A"/>
                <rect x="14" y="6" width="4" height="4" rx="1" fill="#53565A"/>
                <rect x="22" y="6" width="4" height="4" rx="1" fill="#53565A"/>
                <rect x="6" y="14" width="4" height="4" rx="1" fill="#53565A"/>
                <rect x="14" y="14" width="4" height="4" rx="1" fill="#53565A"/>
                <rect x="22" y="14" width="4" height="4" rx="1" fill="#53565A"/>
                <rect x="6" y="22" width="4" height="4" rx="1" fill="#53565A"/>
                <rect x="14" y="22" width="4" height="4" rx="1" fill="#53565A"/>
                <rect x="22" y="22" width="4" height="4" rx="1" fill="#53565A"/>
              </svg>

              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="aurora-nav-header__mobile-icon">
                <path d="M4 7.5C4 6.67157 4.67157 6 5.5 6H26.5C27.3284 6 28 6.67157 28 7.5C28 8.32843 27.3284 9 26.5 9H5.5C4.67157 9 4 8.32843 4 7.5Z" fill="#2B2C2D"/>
                <path d="M4 15.5C4 14.6716 4.67157 14 5.5 14H26.5C27.3284 14 28 14.6716 28 15.5C28 16.3284 27.3284 17 26.5 17H5.5C4.67157 17 4 16.3284 4 15.5Z" fill="#2B2C2D"/>
                <path d="M5.5 22C4.67157 22 4 22.6716 4 23.5C4 24.3284 4.67157 25 5.5 25H26.5C27.3284 25 28 24.3284 28 23.5C28 22.6716 27.3284 22 26.5 22H5.5Z" fill="#2B2C2D"/>
              </svg>
            </button> 
          </div>
        </div>
      </aurora-navbar>

      <aurora-side-nav id="item" 
        .data=${{
          open: this._sideOpen,
          title: this._sideTitle,
          showBackBtn: this._sideShowBackBtn
        }}
        @close="${() => this.close()}"
        @back="${() => this.subnavBack()}"
      >

        ${this._activeSubnav ? html`
          ${this._activeSubnav.navigationPanels.map((panel: any) => 
            panel.columnContent.map((column: any) => html`
              <aurora-side-nav-item slot="upperside" .data=${{
                'title' : column.sectionTitle,
                'isSubnavOption': false,
                'icon': null,
                'href': null,
                'active': false,
                'isSubnav': false,
                'notFirstChild': true,
                'isSubnavHeader': true
              }}></aurora-side-nav-item>

              ${column.menuListContent.map((link: any) => html`
                <aurora-side-nav-item slot="upperside" .data=${{
                  'title' : link.title,
                  'isSubnavOption': true,
                  'icon': null,
                  'href': link.url,
                  'active': false,
                  'isSubnav': false,
                  'notFirstChild': true,
                  'isSubnavHeader': false
                }}></aurora-side-nav-item>
              `)}
              
              <aurora-side-nav-item slot="upperside" .data=${{
                'title' : null,
                'isSubnavOption': false,
                'icon': null,
                'href': null,
                'active': false,
                'isSubnav': false,
                'notFirstChild': false,
                'isSubnavHeader': false,
                'isDivider': true
              }}></aurora-side-nav-item>
            `)
          )}
        ` : html`
        
          ${navigationItems.map((item: any) => html`
            <aurora-side-nav-item
                slot="upperside"
                class="hide-desktop"
                @click="${() => this.subnavClickHandler(item)}"
                .data=${{
                  'title' : item.title,
                  'isSubnavOption': false,
                  'icon': item.image?.imageSrc,
                  'href': item.navigationPanels?.length ? null : item.url,
                  'active': item.active,
                  'isSubnav': item.navigationPanels?.length,
                  'notFirstChild': true,
                  'isSubnavHeader': false
                }}
            ></aurora-side-nav-item>
          `)}
          ${wantToMenus.map((item: any) => html`
            <aurora-side-nav-item
                slot="upperside"
                class="hide-tablet"
                .data=${{
                  'title' : item.title,
                  'isSubnavOption': false,
                  'icon': item.image?.imageSrc,
                  'href': item.href,
                  'active': false,
                  'isSubnav': false,
                  'notFirstChild': false,
                  'isSubnavHeader': false
                }}
            ></aurora-side-nav-item>
          `)}
              
          <aurora-side-nav-item
            slot="downside"
            .data=${{
              'title' : wantToMenu?.title || WANT_TO,
              'isSubnavOption': false,
              'icon': null,
              'href': null,
              'active': true,
              'isSubnav': false,
              'notFirstChild': false,
              'isSubnavHeader': false
            }}
          ></aurora-side-nav-item>
          ${wantToMenus.map((item: any) => html`
            <aurora-side-nav-item
                slot="downside"
                .data=${{
                  'title' : item.title,
                  'isSubnavOption': false,
                  'icon': item.image?.imageSrc,
                  'href': item.href,
                  'active': false,
                  'isSubnav': false,
                  'notFirstChild': true,
                  'isSubnavHeader': false
                }}
            ></aurora-side-nav-item>
          `)}
        `}
      </aurora-side-nav>

      ${!debug ? nothing : html`
        <button @click=${() => this._debug = !this._debug}>Debug</button>

        ${!this._debug ? nothing : html`
          <div style="padding: 30px; border: 1px dashed white; background-color: black; color: white">
            <h2>Debug nav-header</h2>
            <pre>${JSON.stringify(this._internalData, null, 2)}</pre>
          </div>
        `}
      `}
    `
  }
}
