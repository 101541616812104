// Created By: Victor Tommasi

import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';

// styles
import styles from './aurora-input-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export class AuroraInput extends LitElement {
  @property({ type: Object })
  data: {
    label: string;
    id: string;
    name: string;
    type: string;
    value?: string | number | null;
    placeholder?: string;
    autocomplete?: string;
  }

  @query('input')
  inputElement: HTMLInputElement;

  static get styles() {
    return [styles, global, typography];
  }

  updated(changedProperties: Map<string | number | symbol, unknown>) {
    if (changedProperties.has('data') && this.inputElement) {
      this.inputElement.value = this.data.value as string;
    }
  }

  render() {
    const { label, id, name, type, value, placeholder, autocomplete } = this.data;

    return html`
      <label class="aurora-input" for="${id}">
        ${label}
        <slot name="left"></slot>
        <input
          id="${id}"
          name="${name}"
          type="${type}"
          value="${value ?? ''}"
          placeholder="${placeholder}"
          @input="${this.handleChange}"
          @select="${this.handleEvent}"
          @invalid="${this.handleEvent}"
          @reset="${this.handleEvent}"
          @search="${this.handleEvent}"
          @submit="${this.handleEvent}"
          autocomplete="${autocomplete}"
        >
        <slot name="right"></slot>
        <slot name="hint"></slot>
      </label>
    `;
  }
  
  handleChange(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    this.dispatchEvent(new CustomEvent('value', {
      detail: inputElement.value,
      bubbles: true,
      composed: true
    }));
    this.dispatchEvent(new InputEvent('input', event));
  }

  handleEvent(event: Event) {
    this.dispatchEvent(new CustomEvent(event.type, { detail: event.target }));
  }
}

