// Created By: Victor Tommasi

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-search-button-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export class AuroraSearchButton extends LitElement {
  @property({ type: Object })
  data: {
    title: string;
    type: string;
    icon?: boolean;
    forceShowLabel?: boolean;
  }

  @property({ type: Boolean })
  disabled = false;

  static get styles() {
    return [styles, global, typography];
  }

  render() {
    const { title, type, forceShowLabel } = this.data;

    return html`
      <button 
        class="${classMap({
          'aurora-search-button': true,
          'force-show-label': forceShowLabel,
        })}"
        type="${type}"
        ?disabled="${this.disabled}"
      >
        ${this.data.icon
          ? html`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6624 15.1018C17.8101 13.717 18.5 11.9391 18.5 10C18.5 5.58172 14.9183 2 10.5 2C6.08172 2 2.5 5.58172 2.5 10C2.5 14.4183 6.08172 18 10.5 18C12.4391 18 14.217 17.3101 15.6018 16.1624L21.4234 21.9841C21.7163 22.277 22.1912 22.277 22.4841 21.9841C22.777 21.6912 22.777 21.2163 22.4841 20.9234L16.6624 15.1018ZM17 10C17 13.5899 14.0899 16.5 10.5 16.5C6.91015 16.5 4 13.5899 4 10C4 6.41015 6.91015 3.5 10.5 3.5C14.0899 3.5 17 6.41015 17 10Z" fill="white"/>
            </svg>`
          : nothing}
        <span
        >${title}<span>
      </button>
    `
  }
}
