// Created By: artur
import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-nav-item-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

interface IIcon {
  src: string;
  alt: string;
}

interface ILink {
  href: string;
  target: string;
}

interface IMegaMenu {
  children: Array<{
    title: string;
    options: Array<{
      label: string;
      link: ILink;
    }>;
  }>;
}

export class AuroraNavItem extends LitElement {
  @property({ type: Object })
  data: {
    icon?: IIcon;
    link?: ILink;
    label: string;
    active: boolean;
    megamenu?: IMegaMenu;
    megamenuOpen?: boolean;
  };

  static get styles() {
    return [styles, global, typography];
  }

  get hasLink() {
    return !!this.data?.link;
  }

  get hasIcon() {
    return !!this.data?.icon;
  }

  get hasMegamenu() {
    return !!this.data?.megamenu;
  }

  get megamenuIcon() {
    return html`
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_36015_5484)">
          <rect width="16" height="16" fill="white"/>
          <path d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z" fill="#512D6D"/>
        </g>
        <defs>
          <clipPath id="clip0_36015_5484">
            <rect width="16" height="16" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    `;
  }

  getMegamenuTop() {
    const rect = this.getBoundingClientRect();
    return `${rect?.y + rect?.height ?? 0}px`;
  }

  render() {
    if (!this.data) return nothing;
    const { label } = this.data;

    let imageEl = this.hasIcon
      ? html`<img
          src="${this.data.icon.src}"
          alt="${this.data.icon.alt}"
          class="aurora-nav-item-icon"
        />`
      : nothing;
    if (this.hasMegamenu) {
      imageEl = html` <span class="aurora-nav-item-megamenu-icon"> ${this.megamenuIcon} </span> `;
    }

    return html`
      <div
        class="${classMap({ 'aurora-nav-item': true, active: this.data.active })}"
        tabindex="-1"
      >
        <div class="aurora-nav-item-label" tabindex="-1">
          ${
            this.hasLink
              ? html`
                  <a
                    href="${this.data.link.href}"
                    target="${this.data.link.target}"
                    class="aurora-nav-item-link"
                  >
                    <span class="aurora-nav-item-title"> ${label} </span>
                    ${imageEl}
                  </a>
                `
              : html`
                  <span class="aurora-nav-item-title" tabindex="0" role="button"> ${label} </span>
                  ${imageEl}
                `
          }
        </div>
      </div>
        ${
          this.hasMegamenu
            ? html`
                <div
                  class="${classMap({
                    'aurora-nav-item-megamenu-container': true,
                    open: this.data.megamenuOpen,
                    close: !this.data.megamenuOpen,
                    narrow: this.data.megamenu.children.length === 1,
                  })}"
                  style="top: ${this.getMegamenuTop()};"
                >
                  <div
                    class=${classMap({
                      'megamenu-dropdown container': true,
                      open: this.data.megamenuOpen,
                    })}
                  >
                    ${this.data.megamenu.children.map((child) => {
                      return html`
                        <div class="aurora-nav-item-megamenu-column">
                          <span class="aurora-nav-item-megamenu-column-title">${child.title}</span>

                          <ul class="aurora-nav-item-megamenu-column-list">
                            ${child.options.map((option) => {
                              return html`
                                <li class="aurora-nav-item-megamenu-column-list-item">
                                  <a href="${option.link.href}" target="${option.link.target}"
                                    >${option.label}</a
                                  >
                                </li>
                              `;
                            })}
                          </ul>
                        </div>
                      `;
                    })}
                  </div>
                </div>
              `
            : nothing
        }
    `;
  }
}
