// Created By: Victor Tommasi
import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import { 
	constellationScrollTrigger,
	constellationTimelineFrom,
	constellationTimelineTo,
	contentScrollTrigger,
	contentTimelineFrom,
	contentTimelineTo,
	LottieIconsScrollTrigger
} from '../../utils/gsap-utils';
import gsap from 'gsap';
import lottieWeb from 'lottie-web'; // TODO: test importing via cdn
import '../../aurora-homepage-panel/index';

// styles
import styles from './aurora-deliver-panel-css';
import homepanelStyles from '../../aurora-homepage-panel/src/aurora-homepage-panel-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

//types
import { IAuroraPanel } from '../../types/types';

export class AuroraDeliverPanel extends LitElement {
  @property({ type: Object })
  data: IAuroraPanel;

	@query('#deliver-panel') private deliverPanel: HTMLElement;
	@query('#constellation') private constellation: HTMLDivElement;
	@query('#deliver-icon') private deliverIcon: HTMLDivElement;
	@query('#deliver-text') private deliverText: HTMLDivElement;
	@query('#deliver-title') private deliverTitle: HTMLDivElement;
	@query('#deliver-description') private deliverDescription: HTMLDivElement;
	
	private _isDesktop: boolean = window.innerWidth > 992;

  static get styles() {
    return [homepanelStyles, styles, global, typography];
  }

  protected updated(): void {
    this.loadAnimations();
  }

  render() {
    const { title, description, constellationSrc } = this.data;

    return html`
			<aurora-homepage-panel>
				<section id="deliver-panel" class="homepage-panel deliver">
					<div class="section-constellation-wrapper">
						<div class="section-lottie">
							<div id="constellation" class="constellation constellation-deliver">
								<img src="${constellationSrc}" alt="animation" />
							</div>
							<div id="deliver-icon" class="icon-animation icon-deliver"></div>
						</div>
					</div>
					<div id="deliver-text" class="section-content-wrapper deliver-text">
						<div class="content-title" id="deliver-title">
							<h2>${title}</h2>
						</div>
						<div id="deliver-description">
							<p class="body-1">${description}</p>
						</div>
					</div>
				</section>
			</aurora-homepage-panel>
    `;
  }

  private loadAnimations(): void {
		gsap.registerPlugin(ScrollTrigger);

		gsap
			.timeline({
				scrollTrigger: {
					...constellationScrollTrigger,
					trigger: this.deliverPanel
				}
			})
			.from(this.constellation, constellationTimelineFrom)
			.to(this.constellation, constellationTimelineTo);

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.deliverText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.deliverTitle, {	...contentTimelineFrom,	x: 100	})
			.to(this.deliverTitle, {	...contentTimelineTo,	x: 100 });

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.deliverText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.deliverDescription, {	...contentTimelineFrom,	y: 100 })
			.to(this.deliverDescription, {	...contentTimelineTo,	y: 100 });
		
		LottieIconsScrollTrigger({
			rendererSettings: { id: 'deliver-lottie' },
			target: this.deliverIcon,
			path: this.data.animationSrc,
		}, gsap, lottieWeb, ScrollTrigger);
  }
}
