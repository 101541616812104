// Created By: Mateo Trujillo

import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import { 
	constellationScrollTrigger,
	constellationTimelineFrom,
	constellationTimelineTo,
	contentScrollTrigger,
	contentTimelineFrom,
	contentTimelineTo,
	LottieIconsScrollTrigger
} from '../../utils/gsap-utils';
import gsap from 'gsap';
import lottieWeb from 'lottie-web';

// styles
import styles from './aurora-living-system-panel-css';
import homepanelStyles from '../../aurora-homepage-panel/src/aurora-homepage-panel-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

//types
import { IAuroraPanel } from '../../types/types';
import { getAttributeData } from '../../utils/getAttributeData';
import debounce from '../../utils/debounce';

export class AuroraLivingSystemPanel extends LitElement {
  @property({ type: Object, attribute: false })
  data: IAuroraPanel;

  @query('#living-system-panel') private livingSystemPanel: HTMLElement;
  @query('#constellation') private constellation: HTMLDivElement;
  @query('#living-system-icon') private livingSystemIcon: HTMLDivElement;
  @query('#living-system-text') private livingSystemText: HTMLDivElement;
  @query('#living-system-title') private livingSystemTitle: HTMLDivElement;
  @query('#living-system-description') private livingSystemDescription: HTMLDivElement;

  @property({ type: Number, state: true, attribute: false })
  private _isDesktop: boolean = window.innerWidth > 992;

  static get styles() {
    return [homepanelStyles, styles, global, typography];
  }

  protected firstUpdated(): void {
    this.loadAnimations();
  }

  private handleResize = debounce(() => {
    this._isDesktop =  window.innerWidth > 992;
  }, 200);

  connectedCallback() {
    super.connectedCallback();
    this.data = getAttributeData.call(this, 'data');
    window.addEventListener('resize', this.handleResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { title, description, constellationSrc } = this.data;

    return html` 
      <aurora-homepage-panel isLastChild>
        <section id="living-system-panel" class="homepage-panel living-system">
          <div class="section-constellation-wrapper">
            <div class="section-lottie">
              <div id="constellation" class="constellation constellation-living-system">
                <img src="${constellationSrc}" alt="animation" />
              </div>
              <div id="living-system-icon" class="icon-animation icon-living-system"></div>
            </div>
          </div>
          <div id="living-system-text" class="section-content-wrapper living-system-text">
            <div class="content-title" id="living-system-title">
              <h2>${title}</h2>
            </div>
            <div id="living-system-description">
              <p class="body-1">${description}</p>
            </div>
          </div>
        </section>
      </aurora-homepage-panel>
    `;
  }

  private loadAnimations(): void {
    gsap.registerPlugin(ScrollTrigger);

    gsap
			.timeline({
				scrollTrigger: {
					...constellationScrollTrigger,
					trigger: this.livingSystemPanel
				}
			})
			.from(this.constellation, constellationTimelineFrom)
			.to(this.constellation, constellationTimelineTo);

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.livingSystemText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.livingSystemTitle, {	...contentTimelineFrom,	x: 100	})
			.to(this.livingSystemTitle, {	...contentTimelineTo,	x: 100 });

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.livingSystemText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.livingSystemDescription, {	...contentTimelineFrom,	y: 100 })
			.to(this.livingSystemDescription, {	...contentTimelineTo,	y: 100 });
		
		LottieIconsScrollTrigger({
      rendererSettings: { id: 'living-systems-lottie' },
			target: this.livingSystemIcon,
			path: this.data.animationSrc,
		}, gsap, lottieWeb, ScrollTrigger);
  }
}
