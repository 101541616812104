import { html, LitElement, nothing } from 'lit';
import { query, state, property } from 'lit/decorators.js';
import { novantHealthUrl } from '../../constants';
import { classMap } from 'lit/directives/class-map.js';
import {
  deleteLocationCookie,
  getLocationCookie,
  saveLocationCookie,
} from '../../aurora-header/src/templates/location-cookie';

// Styles
import styles from '../src/aurora-location-selector-css';
import globalStyles from '../../styles/global-css';
import typography from '../../styles/typography-css';

export interface IUserGeoLocation {
  lat?: number;
  lng?: number;
  zipCode?: number;
  city?: string;
  address?: string;
  state?: string;
  country?: string;
}
interface IAddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
}
interface IResults extends IItem {
  predictions?: IPredictions[];
  status?: string;
  address_components?: IAddressComponents[];
  formatted_address?: string;
}

interface IGoogleData {
  results: IResults[];
}
interface IPredictions {
  description?: string;
}

interface IItem {
  description?: string;
}

export class AuroraLocationSelector extends LitElement {
  @property({ type: Object })
  data: {
    mobile?: boolean;
  };

  @state() _showMenu = false as boolean;
  @state() _loadingGeoData = false as boolean;
  @state() _localStorageSaved = false as boolean;
  @state() _showResults = false as boolean;
  @state() resultsData: { predictions?: string[] } = {};
  @state() longitude = 0 as number;
  @state() latitude = 0 as number;
  @state() googleGeoData: IGoogleData = { results: [] };
  @state() inputValue = '' as string;
  @state() firstRender = false as boolean;

  @query('#search-input')
  private searchInputEl?: HTMLInputElement;

  private _open: boolean;
  private city: string;
  private state: string;
  private country: string;
  private zip: string;

  static get styles() {
    return [globalStyles, styles, typography];
  }

  get _overlayStyle() {
    let result = ''
    
    if (this.data?.mobile) {
      result += 'bottom: 205px;'
    }

    return result
  }

  private setLocationVariables({ city, zipCode, lat, lng, state, country }: IUserGeoLocation = {}) {
    this.city = city;
    this.latitude = lat;
    this.longitude = lng;
    this.zip = zipCode?.toString();
    this.country = country;
    this.state = state;
  }

  private setInputValue() {
    this.inputValue = [this.city, this.state, this.zip].filter(Boolean).join(', ');
    const searchInput = this.searchInputEl;
    if (searchInput) {
      searchInput.value = this.inputValue;
    }
  }

  private onDocClick = (event: Event) => {
    if (this.isOpen() && !event.composedPath().includes(this)) {
      this.close();
    }
  };

  private open() {
    this._open = true;
  }

  private close() {
    this._open = false;
    this._showMenu = false;
  }

  private isOpen() {
    return this._open;
  }

  handleClick() {
    this._showResults = false;
    this._showMenu = !this._showMenu;
    if (this.isOpen()) {
      this.close();
      this.resultsData = {};
    } else {
      this.open();
      if (!this.firstRender) {
        this.firstRender = true;
      }
    }
  }

  async fetchLocation() {
    const query: string = this.searchInputEl?.value;
    if (query.length >= 3) {
      try {
        const response = await fetch(
          `${novantHealthUrl}/api/locations/autocomplete?input=${query}`,
        );
        this.resultsData = await response.json();
        this._showResults = !!this.resultsData?.predictions?.length;
      } catch (error) {
        console.error('Error: Failed to fetch location data. Please try again later.', error);
      }
    } else {
      this.resultsData = {};
      this._showResults = false;
    }
  }

  async setUserInputLocation(item: IItem) {
    this.setLocationVariables();
    this.setLocation(item);
  }

  async setLocation(item: IItem) {
    try {
      if (item.description) {
        const response = await fetch(
          `${novantHealthUrl}/api/locations/geocode?address=${item.description}`,
        );
        const googleAutoData = await response.json();

        this.googleGeoData = googleAutoData;
        this._localStorageSaved = true;
        this._showResults = false;
        this.longitude = googleAutoData.results[0].geometry.location.lng;
        this.latitude = googleAutoData.results[0].geometry.location.lat;
      }

      const geoData: IResults = this.googleGeoData.results[0];
      const addressComponents = geoData.address_components;

      for (const addressComponent of addressComponents) {
        if (addressComponent.types.includes('administrative_area_level_1')) {
          this.state = addressComponent?.long_name ?? null;
        }

        if (addressComponent.types.includes('postal_code')) {
          this.zip = addressComponent?.long_name ?? null;
        }

        if (addressComponent.types.includes('country')) {
          this.country = addressComponent?.long_name ?? null;
        }

        if (addressComponent.types.includes('locality')) {
          this.city = addressComponent?.long_name ?? null;
        }
      }

      const geoObj: IUserGeoLocation = {
        address: geoData.formatted_address,
        city: this.city,
        lat: this.latitude,
        lng: this.longitude,
        // this.zip is a string, but the API expects a number so we convert it here to a number
        zipCode: this.zip ? +this.zip : null,
        country: this.country,
        state: this.state,
      };

      this.saveUserLocation(geoObj);
      this._localStorageSaved = true;
      this._showMenu = false;
      this.close();
      this.setInputValue();
    } catch (error) {
      console.error('Error: Failed to set location. Please try again later.', error);
    }
  }

  async getLongAndLat() {
    this._loadingGeoData = true;
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.longitude = position.coords.longitude;
            this.latitude = position.coords.latitude;
            resolve({ long: this.longitude, lat: this.latitude });
          },
          (error) => {
            console.error('Error getting location:', error);
            reject(error);
          },
        );
      });
    } else {
      throw new Error('Geolocation is not supported by this browser.');
    }
  }

  checkForLocalStorage() {
    if (window && window.localStorage && window.localStorage.userGeoLocation) {
      const userGeoLocation: IUserGeoLocation = JSON.parse(window.localStorage.userGeoLocation);
      if (userGeoLocation) {
        this._localStorageSaved = true;
        this.setLocationVariables(userGeoLocation);
        this.setInputValue();
        if (!getLocationCookie()) {
          saveLocationCookie(`${userGeoLocation.lat}|${userGeoLocation.lng}`);
          //This is so that browser reloads the current page in case that the page content is different based on location
          location.reload();
        }
      }
    }
  }

  saveUserLocation(geoObj: IUserGeoLocation) {
    window.localStorage.setItem('userGeoLocation', JSON.stringify(geoObj));
    document.dispatchEvent(new CustomEvent('userGeoLocationChangeFromHeader', { detail: geoObj }));
    saveLocationCookie(`${geoObj.lat}|${geoObj.lng}`);
  }

  clearLocalStorage() {
    if (window.localStorage && window.localStorage.userGeoLocation) {
      window.localStorage.removeItem('userGeoLocation');
      document.dispatchEvent(new CustomEvent('userGeoLocationChangeFromHeader', { detail: null }));
      deleteLocationCookie();
      this._localStorageSaved = false;
      this.setLocationVariables();
    }
    this._showMenu = false;
    this._showResults = false;
    this.close();
    this.resultsData = {};
    this.setInputValue();
  }

  async fetchGeolocation() {
    try {
      this.setLocationVariables();
      await this.getLongAndLat();
      if (this.longitude !== 0 && this.latitude !== 0) {
        const response = await fetch(
          `${novantHealthUrl}/api/locations/geocode?lat=${this.latitude}&lng=${this.longitude}`,
        );
        this._loadingGeoData = false;
        this.googleGeoData = await response.json();

        const geoData = this.googleGeoData.results[0];
        const addressComponents = geoData.address_components;
        for (const addressComponent of addressComponents) {
          if (addressComponent.types.includes('postal_code')) {
            this.zip = addressComponent?.long_name ?? null;
          }

          if (addressComponent.types.includes('locality')) {
            this.city = addressComponent.long_name ?? null;
          }

          if (addressComponent.types.includes('administrative_area_level_1')) {
            this.state = addressComponent.short_name ?? null;
          }

          this.setInputValue();
          this._showMenu = false;
        }
        await this.setLocation(geoData);
      }
    } catch (error) {
      console.error('An error occurred while fetching geolocation:', error);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.checkForLocalStorage();
    document.addEventListener('click', this.onDocClick);
  }

  disconnectedCallback() {
    document.removeEventListener('click', this.onDocClick);
  }

  render() {
    return html`
      <div class="aurora-location-selector">
        <div class="button-container">
          <button
            @click="${this.handleClick}"
            type="button"
            class="${classMap({ 'location-button': true, open: this._showMenu })}"
          >
            <span 
              class="${classMap({
                'container-text': true,
                'has-value': this.inputValue?.length > 0,
              })}"
              >${this.inputValue.length > 0
                ? html`${this.inputValue}`
                : html`Select your location`}</span
            >

            <span class="caret">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 8L12 15L19 8" stroke="#C81E82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </button>
        </div>
        ${this.firstRender
          ? html`
              <div
                class="${classMap({
                  'overlay-container': true,
                  open: this._showMenu,
                  close: !this._showMenu,
                  mobile: this.data?.mobile,
                })}"
                style="${this._overlayStyle}"
              >
                <p class="message">Adding your Location helps us provide a better service!</p>
                <h3 class="title">
                  City, State or Zip
                  ${this._localStorageSaved
                    ? html`<button class="clear-button" @click="${this.clearLocalStorage}">
                        Clear
                      </button>`
                    : nothing}
                </h3>

                <div class="input-buttons">
                  <div class="input-container">
                    <input
                      id="search-input"
                      class="search-input"
                      @keyup="${this.fetchLocation}"
                      placeholder="Enter City or Zip"
                      type="search"
                      value="${this.inputValue}"
                    />

                    <button class="cancel-button" @click="${this.clearLocalStorage}"></button>
                  </div>

                  <div class="get-location" @click="${this.fetchGeolocation}">
                    ${this._loadingGeoData
                      ? html` <div class="loader"></div> `
                      : html`
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M15.0004 30.6V28.1C11.9559 27.7889 9.42261 26.6222 7.40039 24.6C5.37817 22.5778 4.2115 20.0444 3.90039 17H1.40039V15H3.90039C4.2115 11.9555 5.37817 9.42222 7.40039 7.39999C9.42261 5.37777 11.9559 4.21111 15.0004 3.89999V1.39999H17.0004V3.89999C20.0448 4.21111 22.5782 5.37777 24.6004 7.39999C26.6226 9.42222 27.7893 11.9555 28.1004 15H30.6004V17H28.1004C27.7893 20.0444 26.6226 22.5778 24.6004 24.6C22.5782 26.6222 20.0448 27.7889 17.0004 28.1V30.6H15.0004ZM16.0004 26.1333C18.7782 26.1333 21.1615 25.1389 23.1504 23.15C25.1393 21.1611 26.1337 18.7778 26.1337 16C26.1337 13.2222 25.1393 10.8389 23.1504 8.84999C21.1615 6.8611 18.7782 5.86666 16.0004 5.86666C13.2226 5.86666 10.8393 6.8611 8.85039 8.84999C6.8615 10.8389 5.86706 13.2222 5.86706 16C5.86706 18.7778 6.8615 21.1611 8.85039 23.15C10.8393 25.1389 13.2226 26.1333 16.0004 26.1333ZM16.0004 21C14.6004 21 13.4171 20.5167 12.4504 19.55C11.4837 18.5833 11.0004 17.4 11.0004 16C11.0004 14.6 11.4837 13.4167 12.4504 12.45C13.4171 11.4833 14.6004 11 16.0004 11C17.4004 11 18.5837 11.4833 19.5504 12.45C20.5171 13.4167 21.0004 14.6 21.0004 16C21.0004 17.4 20.5171 18.5833 19.5504 19.55C18.5837 20.5167 17.4004 21 16.0004 21Z"
                              fill="black"
                            />
                          </svg>
                        `}
                  </div>
                </div>

                ${this._showResults
                  ? html`
                      <ul class="location-item-container">
                        ${html`${this.resultsData.predictions.map((item: any) => {
                          return html`<li
                            class="location-item"
                            @click="${() => {
                              this.setUserInputLocation(item);
                            }}"
                          >
                            ${item.description}
                          </li>`;
                        })}`}
                      </ul>
                    `
                  : nothing}
              </div>
            `
          : nothing}
      </div>
    `;
  }
}
