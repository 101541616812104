// Created By: artur

import { html, LitElement } from 'lit';

// styles
import styles from './aurora-navbar-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export class AuroraNavbar extends LitElement {
  static get styles() {
    return [styles, global, typography];
  }

  render() {
    return html`
      <nav class="aurora-navbar">
        <div class="logo-container">
          <slot name="logo"></slot>
        </div>

        <slot class="nav-container" name="nav"></slot>
        
        <div class="right-container">
          <slot name="right"></slot>
        </div>
      </nav>
    `;
  }
}
