// Created By: Mateo Trujillo
import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-tertiary-nav-item-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export class AuroraTertiaryNavItem extends LitElement {
  @property({ type: Object })
  data: {
    title: string;
    active?: boolean;
  }

  static get styles() {
    return [styles, global, typography];
  }

  private handleClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.dispatchEvent(new CustomEvent('click', e));
  };

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const { title } = this.data;

    return html`
        <button @click=${this.handleClick} class="${classMap({ 
          "aurora-tertiary-nav-item" : true, "active": this.data.active
        })}">${title}</button>`
  }
}
