// Created By: Praveen

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { map } from 'lit/directives/map.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-text-block-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

// Components
import '../../aurora-link-button/index';
import '../../aurora-text-button/index';

//types
import { IButton } from '../../types/types';

export class AuroraTextBlock extends LitElement {
  @property({ type: Object })
  data: {
    title: string;
    description?: string;
    hasSeperator?: boolean;
    primaryButton?: IButton;
    secondaryButton?: IButton;
    linkButtons?: IButton[];
    hasPadding?: boolean;
    variant?: 'gradient' | 'grey' | 'light' | 'dark';
    alignment?: 'left' | 'center';
    useH1Title?: boolean;
  }

  static get styles() {
    return [styles, global, typography];
  }

  get hasButtons() {
    return this.data.primaryButton || this.data.secondaryButton;
  }

  get darkVariantProps() {
    return this.data?.variant === 'dark' ? ['over-dark'] : []
  }

  connectedCallback() {
    super.connectedCallback();
    if (!this.data.alignment) {
      this.data.alignment = 'left';
    }
  }

  render() {
    const { 
      title,
      description,
      primaryButton,
      secondaryButton,
      hasSeperator,
      linkButtons,
      alignment,
      hasPadding,
      variant,
      useH1Title } = this.data;

    return html`
      <section class="${classMap({
          'text-block': true,
          [alignment]: true,
          'optional-padding': hasPadding,
          ...(variant && { [variant]: true })
        })}"
      >

        ${ useH1Title
          ? html `
            <h1 class="text-block-title">
              ${title}
            </h1>`
          :
          html `
            <h2 class="text-block-title">
              ${title}
            </h2>`
        }

        ${hasSeperator 
          ? html `<hr class="text-block-seperator"/>`
          
          : nothing
        }

        ${description
          ? html `
          <div class="text-block-description">
          ${unsafeHTML(description)}
          </div>`

          : nothing
        }

        ${ (this.hasButtons || linkButtons?.length > 0) ? html`
          <div class="call-to-action">
            ${ this.hasButtons 
              ? html `
                ${ primaryButton
                  ? html`<aurora-link-button
                    .buttondata="${{
                        ...primaryButton,
                        buttonStyles: [
                          'primary full-width-button',
                          this.darkVariantProps
                        ]
                      }}"
                    />`
                  : nothing}
              
                  ${ primaryButton && secondaryButton
                    ? html `<aurora-link-button
                      .buttondata="${{
                        ...secondaryButton,
                        buttonStyles: [
                          'secondary full-width-button',
                          this.darkVariantProps
                          ]
                        }}"
                      />`
                    : nothing}`

                : nothing 
            }

            ${ !this.hasButtons && linkButtons?.length > 0
              ? html`
              <div class="text-buttons">
                    ${map(
                      linkButtons,
                      (linkbtn) =>
                        html` <aurora-text-button
                          .textdata="${linkbtn}"
                        />`,
                    )}
                  </div>`
              : nothing 
            }
          </div> `

          : nothing 
        }
        
      </section>`
  }
}
