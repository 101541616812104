// Created By: Mateo
import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import { 
	constellationScrollTrigger,
	constellationTimelineFrom,
	constellationTimelineTo,
	contentScrollTrigger,
	contentTimelineFrom,
	contentTimelineTo,
	LottieIconsScrollTrigger
} from '../../utils/gsap-utils';
import gsap from 'gsap';
import lottieWeb from 'lottie-web'; // TODO: test importing via cdn
import '../../aurora-homepage-panel/index'

// styles
import styles from './aurora-document-panel-css';
import homepanelStyles from '../../aurora-homepage-panel/src/aurora-homepage-panel-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

interface IDocumentPanel {
  title: string;
  description: string;
  constellationSrc: string;
	animationSrc: string;
}

export class AuroraDocumentPanel extends LitElement {
  @property({ type: Object })
  data: IDocumentPanel;

  @query('#document-panel') private documentPanel: HTMLElement;
  @query('#constellation') private constellation: HTMLDivElement;
  @query('#document-icon') private documentIcon: HTMLDivElement;
  @query('#document-text') private documentText: HTMLDivElement;
  @query('#document-title') private documentTitle: HTMLDivElement;
  @query('#document-description') private documentDescription: HTMLDivElement;

	private _isDesktop: boolean = window.innerWidth > 992;

  static get styles() {
    return [homepanelStyles, styles, global, typography];
  }

  protected updated(): void {
    this.loadAnimations();
  }

  render() {
    const { title, description, constellationSrc } = this.data;

    return html`
      <aurora-homepage-panel>
        <section id="document-panel" class="homepage-panel document">
					<div class="section-constellation-wrapper">
						<div class="section-lottie">
							<div id="constellation" class="constellation constellation-document">
								<img src="${constellationSrc}" alt='animation' />
							</div>
							<div id="document-icon" class="icon-animation icon-document"></div>
						</div>
					</div>
          <div id="document-text" class="section-content-wrapper document-text">
						<div class="content-title" id="document-title">
							<h2>${title}</h2>
						</div>
						<div id="document-description">
							<p class="body-1">${description}</p>
						</div>
          </div>
        </section>
      </aurora-homepage-panel>
    `;
  }

  private loadAnimations(): void {
    gsap.registerPlugin(ScrollTrigger);

		gsap
			.timeline({
				scrollTrigger: {
					...constellationScrollTrigger,
					trigger: this.documentPanel
				}
			})
			.from(this.constellation, { ...constellationTimelineFrom, rotation: -79 })
			.to(this.constellation, constellationTimelineTo);

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.documentText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.documentTitle, {	...contentTimelineFrom,	x: 100	})
			.to(this.documentTitle, {	...contentTimelineTo,	x: 100 });

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.documentText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.documentDescription, {	...contentTimelineFrom,	y: 100 })
			.to(this.documentDescription, {	...contentTimelineTo,	y: 100 });
		
		LottieIconsScrollTrigger({
			rendererSettings: { id: 'document-lottie' },
			target: this.documentIcon,
			path: this.data.animationSrc,
		}, gsap, lottieWeb, ScrollTrigger);
  }
}
