// Created By: artur

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { domPurify } from '../../utils/domPurify';
import '../../aurora-text-button/index'

// styles
import styles from './aurora-results-card-css';
import global from '../../styles/global-css';

export class AuroraResultsCard extends LitElement {
    @property({ type: Object, attribute: true })
    data: {
      title: string;
      description?: string;
      searchTerm?: string;
      linkText: string;
      url: string,
      hitType?: string,
      hitId?: string
    }

    static get styles() {
      return [styles, global];
    }

    render() {
      const { searchTerm, linkText, url, hitType, hitId, title, description } = this.data;
      const textButtonData = { url, 'title': linkText };
      
      return html`
        <div class="aurora-results-card">
          <div class="aurora-results-header">
            ${searchTerm 
              ? html`<span class="aurora-results-search-term">
                  ${searchTerm}
                </span>`
              : nothing
            }

            <span class="aurora-results-title">
              ${title}
            </span>
          </div>

          <div class="aurora-results-body">
            ${description
              ? html`
                <p class="aurora-results-description"
                  .innerHTML= ${domPurify(description)}>
                </p>
              `
              : nothing
            }
          </div>

          <div class="aurora-results-footer">
            <aurora-text-button
              .textdata='${textButtonData}'
              data-hitid="${hitType && hitId ? `${hitType}/${hitId}` : undefined}"
            />
          </div>

           ${ url ? html
          `<a href=${url} 
            class="clickable-area" 
            role="button" 
            aria-label=${title}>
          </a>` 
          : nothing 
        }
        </div>
      `
    }

}