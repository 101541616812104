// Created By: artur

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-unified-search-mobile-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

// components
import '../../aurora-select/index';
import '../../aurora-location-selector/index';
import '../../aurora-input/index';
import '../../aurora-date-input/index';
import '../../aurora-search-button/index';
import '../../aurora-unified-search-autocomplete/index';

// utils
import { formatDate } from '../../utils/date';

export class AuroraUnifiedSearchMobile extends LitElement {
  @property({ type: Object })
  data: {
    hideDatepicker?: boolean;
    title?: string;
    searchHref?: string;
    input?: {
      placeholder: string;
    };
    dateInput?: {
      placeholder: string;
    };
    select?: {
      placeholder: string;
      options: {
        value: string|number;
        label: string;
        hideDatepicker?: boolean;
        url?: string;
      }[];
    };
  }

  static get styles() {
    return [styles, global, typography];
  }

  @property({ type: Boolean, attribute: 'open' })
  _open = false;

  @property({ type: Boolean, attribute: 'hide' })
  _hide = false;

  @property({ type: Boolean, attribute: 'noUnifiedHeroSearchPresent' })
  _noUnifiedHeroSearchPresent = true;

  @property({ type: Object, attribute: false })
  _form = {
    selectValue: '',
    inputValue: '',
    dateValue: {
      startDate: null,
      endDate: null,
    } as Record<string, Date>
  }

  connectedCallback() {
    super.connectedCallback();

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('hide-aurora-unified-search')) {
      this._hide = true;
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }
  
  onValue = (e: CustomEvent, key: string) => {
    if (!(key in this._form)) return;
    this._form[key as keyof typeof this._form] = e.detail;

    if (key === 'selectValue') {
      const selectOption = this.data?.select?.options.find(o => o.value === e.detail);
      if (selectOption?.hideDatepicker) this._form.dateValue = {};
    }
    
    this.dispatchEvent(new CustomEvent(key, e));
    this.requestUpdate();
  }

  onSearch() {
    const url = new URL(this.data?.searchHref || window.location.href);

    // Set 'query' parameters
    // Todo: add proper url handling. This is for alpha testing only
    ['keyword', 'query', 'q'].forEach(param => {
      if (url.toString().includes(`${param}=`)) {
        url.searchParams.set(param, this._form.inputValue);
      }
    });
    
    // Set date parameters if present
    const { startDate, endDate } = this._form.dateValue;
    if (startDate) {
      url.searchParams.set('startDate', formatDate(startDate));
    }
    if (endDate) {
      url.searchParams.set('endDate', formatDate(endDate));
    }

    // Redirect to the updated URL
    if (window.location.host.includes('novanthealth.org')) {
      window.location.href = url.toString();
    } else {
      window.open(url.toString(), '_self');
    }
  }

  render() {
    if (this._hide) return nothing;

    const { hideDatepicker, select, input, dateInput, title } = this.data || {};

    return html`
      <section
        class="${classMap({
          'aurora-unified-search-mobile': true,
          'aurora-unified-search-mobile_open': this._open
        })}"
      >
        <button class="aurora-unified-search-mobile_toggle-btn" @click=${() => this._open = !this._open}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6624 15.1018C17.8101 13.717 18.5 11.9391 18.5 10C18.5 5.58172 14.9183 2 10.5 2C6.08172 2 2.5 5.58172 2.5 10C2.5 14.4183 6.08172 18 10.5 18C12.4391 18 14.217 17.3101 15.6018 16.1624L21.4234 21.9841C21.7163 22.277 22.1912 22.277 22.4841 21.9841C22.777 21.6912 22.777 21.2163 22.4841 20.9234L16.6624 15.1018ZM17 10C17 13.5899 14.0899 16.5 10.5 16.5C6.91015 16.5 4 13.5899 4 10C4 6.41015 6.91015 3.5 10.5 3.5C14.0899 3.5 17 6.41015 17 10Z"/>
          </svg>
          <div>Search</div>
        </button>

        <div class="aurora-unified-search-mobile_content">
          <div class="aurora-unified-search-mobile_title">
            ${title}
          </div>
          
          <div class="aurora-unified-search-mobile_zipcode">
            <aurora-location-selector .data=${{
              mobile: true
            }}></aurora-location-selector>
          </div>

          <div class="aurora-unified-search-mobile_select">
            <aurora-select 
              .data=${{
                placeholder: select?.placeholder || '',
                value: this._form.selectValue || '',
                options: select?.options || []
              }}
              @value=${(e: CustomEvent) => this.onValue(e, 'selectValue')}
            ></aurora-select>
          </div>

          <div class="aurora-unified-search-mobile_input">
            <aurora-unified-search-autocomplete
              .data=${{
                placeholder: 'Select an item from the list below.',
                inputPlaceHolder: input?.placeholder || '',
                inputValue: this._form.inputValue || '',
                selectValue: this._form.selectValue || '',
              }}
              @value=${(e: CustomEvent) => this.onValue(e, 'inputValue')}
            ></aurora-unified-search-autocomplete>
          </div>

          ${hideDatepicker ? nothing : html`
            <div class="aurora-unified-search-mobile_datepicker">
              <aurora-date-input
                .data=${{
                  placeholder: dateInput?.placeholder || '',
                  value: this._form.dateValue || '',
                  useDaterange: true,
                  mobile: true
                }}
                @value=${(e: CustomEvent) => this.onValue(e, 'dateValue')}
              ></aurora-date-input>
            </div>
          `}
          
          <div class="aurora-unified-search-mobile_btn">
            <aurora-search-button 
              .data=${{
                title: 'Search',
                icon: true,
                forceShowLabel: true
              }}
              @click=${this.onSearch}
            ></aurora-search-button>
          </div>
        
          <button class="aurora-unified-search-mobile_close-btn" @click=${() => this._open = !this._open}>
            Close
          </button>
        </div>
      </section>`
  }
}
