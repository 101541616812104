// Created By: Joe Kanakaraj

import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { domPurify } from '../../utils/domPurify';
import { classMap } from 'lit/directives/class-map.js';
import { tabIndex } from '../../utils/getTabIndex';

// styles
import styles from './aurora-reveal-card-css';
import global from '../../styles/global-css';
import { IButtons } from '../../types/buttons';
import { Image } from '../../types/types';
import debounce from '../../utils/debounce';

export class AuroraRevealCard extends LitElement {
  @property({ type: Object })
  data: {
    title: string;
    subtitle: string;
    description: string;
    image: Image;
    button: IButtons;
    tabindex?: number;
  };

  @state()
  _isOpen = false;

  @state()
  _pageWidth: number = window.innerWidth;

  static get styles() {
    return [styles, global];
  }

  private handleResize = (): void => {
    this._pageWidth = window.innerWidth;
  };

  private handleClick = (): void => {
    if (this._pageWidth < 992) {
      this._isOpen = !this._isOpen;
    }
  }

  private handleMouseOverAndFocus = (): void => {
    if (this._pageWidth >= 992) {
      this._isOpen = true;
    }
  }

  private handleMouseOut = (): void => {
    if (this._pageWidth >= 992) {
      this._isOpen = false;
    }
  }

  private handleFocusOut = (): void => {
    this._isOpen = false
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', debounce(this.handleResize, 200));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { title, description, image, subtitle, button } = this.data;
    const { localName: parentName } = this.parentElement;
    
    return html` <div
      @click=${this.handleClick}
      @mouseover=${this.handleMouseOverAndFocus}
      @focus=${this.handleMouseOverAndFocus}
      @mouseout=${this.handleMouseOut}
      @focusout=${this.handleFocusOut}
      class=${classMap({
        'reveal-card': true,
        'reveal-card--expanded': this._isOpen,
        'layout-item': parentName === 'aurora-reveal-card-layout'
      })}
      tabindex=${tabIndex(this.data?.tabindex)}
      aria-controls="description"
    >
      <div class="image-container">
        ${image ? html`<aurora-img .data="${{ backgroundImage: true, ...image  }}"></aurora-img>` : nothing}
      </div>
      <div class="hide-show">
        <span></span>
      </div>
      <div class="content-container">
        <h3 class="title">${title}</h3>
        <h4 class="subtitle">${subtitle}</h4>
        <div id="description" class="description" .innerHTML=${domPurify(description)}></div>
      </div>
      <div class="button-well">
        ${button
          ? html` <aurora-link-button .buttondata="${button}"></aurora-link-button> `
          : nothing}
      </div>
    </div>`;
  }
}
