// Created By: Victor Tommasi
import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import { 
	constellationScrollTrigger,
	constellationTimelineFrom,
	constellationTimelineTo,
	contentScrollTrigger,
	contentTimelineFrom,
	contentTimelineTo,
	LottieIconsScrollTrigger
} from '../../utils/gsap-utils';
import gsap from 'gsap';
import lottieWeb from 'lottie-web'; // TODO: test importing via cdn

// styles
import styles from './aurora-design-panel-css';
import homepanelStyles from '../../aurora-homepage-panel/src/aurora-homepage-panel-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

//types
import { IAuroraPanel } from '../../types/types';

//Components
import('./step1/designStep1');
import('./step2/designStep2');

export class AuroraDesignPanel extends LitElement {
  @property({ type: Object })
  data: IAuroraPanel;

	@query('#design-panel') private designPanel: HTMLElement;
	@query('#constellation') private constellation: HTMLDivElement;
	@query('#design-icon') private designIcon: HTMLDivElement;
	@query('#design-text') private designText: HTMLDivElement;
	@query('#design-title') private designTitle: HTMLDivElement;
	@query('#design-description') private designDescription: HTMLDivElement;

	private _isDesktop: boolean = window.innerWidth > 992;

  static get styles() {
    return [homepanelStyles, styles, global, typography];
  }

  protected updated(): void {
		gsap.registerPlugin(ScrollTrigger);
    this.loadAnimations();
  }

  render() {
    const { title, description, constellationSrc } = this.data;

		return html`
			<aurora-homepage-panel>
				<section id="design-panel" class="homepage-panel design">
					<div class="section-constellation-wrapper">
						<div class="section-lottie">
							<div id="constellation" class="constellation constellation-design">
								<img src="${constellationSrc}" alt='animation' />
							</div>
							<div id="design-icon" class="icon-animation icon-design"></div>
						</div>
					</div>
					<div id="design-text" class="section-content-wrapper design-text">
						<div class="content-title" id="design-title">
							<h2>${title}</h2>
						</div>
						<div id="design-description">
							<p class="body-1"> ${description}</p>
						</div>
					</div>
				</section>
			</aurora-homepage-panel>
			<design-step-1></design-step-1>
			<design-step-2></design-step-2>
		`
	}

  private loadAnimations(): void {
		gsap
			.timeline({
				scrollTrigger: {
					...constellationScrollTrigger,
					trigger: this.designPanel
				}
			})
			.from(this.constellation, constellationTimelineFrom)
			.to(this.constellation, constellationTimelineTo);

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.designText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.designTitle, {	...contentTimelineFrom,	x: 100	})
			.to(this.designTitle, {	...contentTimelineTo,	x: 100 });

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.designText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.designDescription, {	...contentTimelineFrom,	y: 100 })
			.to(this.designDescription, {	...contentTimelineTo,	y: 100 });
		
			LottieIconsScrollTrigger({
				rendererSettings: { id: 'design-lottie' },
				target: this.designIcon,
				path: '../static/assets/lottie/design.json',
			}, gsap, lottieWeb, ScrollTrigger);
  }
}
