// Created By: artur

import { html, LitElement, PropertyValueMap } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { nothing } from 'lit/html.js';

import Pikaday from 'pikaday';

// styles
import styles from './aurora-date-input-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

// Pikaday is a datepicker library link to doc: https://github.com/Pikaday/Pikaday

export type DateRange = {
  startDate: Date;
  endDate: Date;
}

export class AuroraDateInput extends LitElement {
  @property({ type: Object })
  data: {
    placeholder?: string;
    value?: Date|string|DateRange;
    useDaterange?: boolean;
    mobile?: boolean;
  }

  @query('.aurora-date-input__input')
  private _inputEl?: HTMLInputElement;

  @query('.aurora-date-input__datepickers-container')
  private _datepickerContainer?: HTMLDialogElement;

  @property({ type: Boolean })
  _datepickerDialogOpen = false;

  @property({ type: Object })
  _datepicker: any;

  @property({ type: Object })
  _value: any;

  @property({ type: Boolean })
  _above = false;


  static get styles() {
    return [styles, global, typography];
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('click', this.onDocumentClick);

    if (this.data?.value) {
      this._value = typeof this.data.value === 'string' ? new Date(this.data.value) : this.data.value;
    }

    // window width change listener
    window.addEventListener('resize', () => {
      this._datepicker.destroy();
      this.createDatepickers();
    });

    // rotation listener
    window.addEventListener('orientationchange', () => {
      this._datepicker.destroy();
      this.createDatepickers();
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this.onDocumentClick);
    window.removeEventListener('resize', () => {
      this._datepicker.destroy();
      this.createDatepickers();
    });
    window.removeEventListener('orientationchange', () => {
      this._datepicker.destroy();
      this.createDatepickers();
    });
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.firstUpdated(_changedProperties);
    this.createDatepickers();
  }

  onSelect = (e: any) => {
    if (this.data?.useDaterange) {
      if (!this._value) this._value = { startDate: null, endDate: null };

      if (this._value.endDate && this._value.endDate?.getTime() === e.getTime()) {
        this._value.endDate = null;
        this._datepicker.setEndRange(null);
      } else if (this._value.startDate && this._value.startDate > e) {
        this._value.startDate = e;
        this._datepicker.setStartRange(e);
      } else if (this._value.startDate && this._value.endDate && this._value.startDate < e && this._value.endDate > e) {
        this._value.startDate = e;
        this._datepicker.setStartRange(e);
      } else if (this._value.endDate && this._value.endDate < e) {
        this._value.endDate = e;
        this._datepicker.setEndRange(e);
      } else if (!this._value.startDate) {
        this._value.startDate = e;
        this._datepicker.setStartRange(e);
      } else {
        this._value.endDate = e;
        this._datepicker.setEndRange(e);
      }

      this._datepicker.hide()
      this._datepicker.show()
      const inputString = this.onToString(null)
      this._inputEl.value = inputString;
    } else {
      this._value = e
    }
    
    this.requestUpdate();

    this.dispatchEvent(new CustomEvent('value', {
      detail: {
        startDate: this._value.startDate,
        endDate: this._value.endDate,
      },
      bubbles: true,
      composed: true,
    }));
  }

  toStringDate = (date: Date, getYear = false) => {
    let result = '';

    // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    result += months[date.getMonth()] + ' ';

    result += date.getDate();
    if (getYear) result += ', ' + date.getFullYear();

    return result;
  }

   toCalendarStringDate(date: Date) {
    // format = mm/dd/yyyy
    let result = '';
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    result = `${month}/${day}/${year}`;
    
    return result;
  }

  onToString = (date: Date) => {
    let result = '';
    if (this.data?.useDaterange) {
      const startDateShowYear = this._value?.startDate?.getFullYear() !== this._value?.endDate?.getFullYear();
      const start = this._value?.startDate ? this.toStringDate(this._value?.startDate, startDateShowYear) : '';
      const end = this._value?.endDate ? this.toStringDate(this._value?.endDate, true) : '';
      result = `${start}${end && start ? ' - ' : ''}${end}`
    } else {
      result = this.toStringDate(date, true);
    }

    return result;
  }

  createDatepickers() {
    const mobile = window.innerWidth < 500;
    this._datepicker = new Pikaday({
      field: this._inputEl,
      numberOfMonths: mobile ? 1 : 2,
      bound: false,
      container: this._datepickerContainer,
      toString: this.onToString,
      onSelect: this.onSelect,
    });

    if (this._value) {
      this._datepicker.setDate(this._value);
    }
  }

  onDocumentClick = () => {
    if (!this._datepickerDialogOpen) return

    this._datepickerDialogOpen = false;
  }

  onClear() {
    this._value = null;
    this._datepicker.setDate(null);
    this._datepicker.setStartRange(null);
    this._datepicker.setEndRange(null);
    this._datepicker.hide()
    this._datepicker.show()
    
    this.dispatchEvent(new CustomEvent('value', {
      detail: {
        startDate: null,
        endDate: null,
      },
      bubbles: true,
      composed: true,
    }));

    this.requestUpdate();
  }

  onClick(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this._datepickerDialogOpen = !this._datepickerDialogOpen;

  }

  onClickContainer(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  get dialogStyle() {
    // calculate the position of the dialog based on the space available bellow. If theres enough space define as bellow if not defines as above
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const rect = this._inputEl?.getBoundingClientRect();

    const above = windowHeight - rect?.bottom < 330; // 310 pixels is the height of the datepicker dialog
    // theres more than 413 px to the left of the input
    const spaceToRight = windowWidth - ((rect?.right - rect?.width) || 0); 

    // as the dialog is positioned fixed we need to calculate the top and bottom position
    const top = above ? 'unset' : (rect?.bottom  + 9)+ 'px';
    // const bottom = above ? (windowHeight - rect?.top + 19) + 'px' : 'unset';
    const bottom = above ? (windowHeight - rect?.top + 9) + 'px' : 'unset';
    const right = windowWidth - ((rect?.right + 34) || 0) + 'px'

    
    let left = spaceToRight < 413 ? 'unset' : (rect?.left - 13) + 'px';
    if (this.data?.mobile) left = '24px'

    return {
      top,
      bottom,
      left,
      right
    }
  }

  render() {
    const { useDaterange = false, placeholder = '' } = this.data || {};

    return html`
      <label class="aurora-date-input" @click="${this.onClick}">
        <input
          class="aurora-date-input__input"
          placeholder="${placeholder}"
        />

        <button class="aurora-date-input__btn">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.7763 3.2013H18.6645V2.10065C18.6645 1.49497 18.1645 1 17.5526 1C16.9408 1 16.4408 1.49497 16.4408 2.10065V3.2013H7.55263V2.10065C7.55263 1.49497 7.05263 1 6.44737 1C5.84211 1 5.33553 1.49497 5.33553 2.10065V3.2013H4.22368C2.99342 3.2013 2.01316 4.19124 2.01316 5.40261L2 20.7987C2 22.0101 2.98684 23 4.22368 23H19.7763C21 23 22 22.0101 22 20.7987V5.40261C22 4.19124 21 3.2013 19.7763 3.2013ZM19.7763 19.698C19.7763 20.3037 19.2763 20.7987 18.6645 20.7987H5.33553C4.72368 20.7987 4.22368 20.3037 4.22368 19.698V8.69805H19.7763V19.698Z" fill="#512D6D"/>
          <path d="M8.67106 11.5506H6.44738V13.7519H8.67106V11.5506Z" fill="#512D6D"/>
          <path d="M13.1118 11.5506H10.8882V13.7519H13.1118V11.5506Z" fill="#512D6D"/>
          <path d="M17.5592 11.5506H15.3355V13.7519H17.5592V11.5506Z" fill="#512D6D"/>
          <path d="M8.67106 15.8816H6.44738V18.0829H8.67106V15.8816Z" fill="#512D6D"/>
          <path d="M13.1118 15.8816H10.8882V18.0829H13.1118V15.8816Z" fill="#512D6D"/>
          <path d="M17.5592 15.8816H15.3355V18.0829H17.5592V15.8816Z" fill="#512D6D"/>
          </svg>
        </button>

        <div
          class="${classMap({
            'aurora-date-input__datepicker-container': true,
            'open': this._datepickerDialogOpen
          })}" 
          style="${styleMap(this.dialogStyle)}"
          @click="${this.onClickContainer}"
        >
          ${!useDaterange ? nothing : html`
            <div class="aurora-date-input__selected-values">
              <div class="aurora-date-input__startdate-container">
                Start - ${this._value?.startDate ? this.toCalendarStringDate(this._value?.startDate) : ''}
              </div>
    
              <div class="aurora-date-input__enddate-container">
                End - ${this._value?.endDate ? this.toCalendarStringDate(this._value?.endDate) : ''}
              </div>

              <button class="aurora-date-input__clear-btn" @click=${this.onClear}>Clear</button>
            </div>
          `}

          <div class="aurora-date-input__datepickers-container"></div>
        </div>
      </label>
      
    `
  }
}
