// Created By: Yasmine Jimenez
import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import('./auroraAccordionPanel');

// styles
import global from '../../styles/global-css';
import styles from './aurora-accordion-css';

export class AuroraAccordion extends LitElement {
  @property()
  variant: 'primary' | 'light' = 'primary';

  static get styles() {
    return [styles, global];
  }

  render() {
    return html`
      <div class="aurora-accordion ${this.variant}">
        <slot></slot>
      </div>
    `;
  }
}
