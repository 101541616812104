// Created By: Joe Kanakaraj
import { html, LitElement } from 'lit';

import styles from './aurora-homepage-panel-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

type IAuroraHomepagePanel = {
  height: number,
}
export class AuroraHomepagePanel extends LitElement {
  @property({ type: Boolean })
  isLastChild = false;

  @property({ type: Object })
  data: IAuroraHomepagePanel = {
    height: 300
  };

  static get styles() {
    return [styles, global, typography];
  }
  
  render() {
    return html`
      <div style="height: ${this.data?.height}vh;" class=${classMap({ 'homepage-panel-container': true, 'last-child': this.isLastChild })}>
        <slot></slot>
      </div>
    `;
  }
}
