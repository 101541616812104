// Created By: Victor Tommasi

import { html, LitElement, nothing, PropertyValues } from 'lit';
import { property, state } from 'lit/decorators.js';
import { domPurify } from '../../utils/domPurify';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-dropdown-base-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';
import { isObjectEmpty } from '../../utils/object';

interface IDropdownData {
  items: IDropdownItem[];
  placeholder?: string;
  noResultMessage?: {
    placeholder: string;
    label: string;
  }
}

interface IDropdownItem {
  category?: string;
  label: string;
  value: string;
  subLabel?: string;
}

type ParsedData = {
  [key: string]: IDropdownItem[];
};

export class AuroraDropdownBase extends LitElement {
  @property({ type: Object })
  data?: IDropdownData;

  @state()
  private _parsedData: ParsedData;

  @state()
  private _isOpen: boolean;

  @state()
  private _dropup: boolean;

  private _UNCATEGORIZED = 'uncategorized';

  static get styles() {
    return [styles, global, typography];
  }

  constructor() {
    super();
    this.data = { items: [], placeholder: '' };
    this._parsedData = null;
    this._isOpen = false;
    this._dropup = false;
  }

  connectedCallback(): void {
    super.connectedCallback();
    document.addEventListener('click', this.onClickOutside);
    this.addEventListener('keydown', this.handleKeyboardNavigation);
  }
  
  disconnectedCallback(): void {
    super.disconnectedCallback();
    document.removeEventListener('click', this.onClickOutside);
    this.removeEventListener('keydown', this.handleKeyboardNavigation);
  }

   // Check if the dropdown should be displayed as a dropup
   private checkDropup() {
    const boundingRect = this.getBoundingClientRect();
    const dropdownHeight = 440; // Assuming a max height of 440px for the dropdown
    const viewportHeight = window.innerHeight;

    this._dropup = boundingRect.bottom + dropdownHeight > viewportHeight;
  }

  // Enhancement for keyboard navigation
  handleKeyboardNavigation(event: KeyboardEvent): void {
    if (!this._isOpen) return;

    const focusedItem = this.shadowRoot.activeElement as HTMLElement;
    let newItem: HTMLElement | null = null;

    if (event.key === 'ArrowDown') {
      newItem = focusedItem.nextElementSibling as HTMLElement;
    } else if (event.key === 'ArrowUp') {
      newItem = focusedItem.previousElementSibling as HTMLElement;
    }

    if (newItem) newItem.focus();
  }

  onClickOutside = (event: MouseEvent) => {
    if (!this._isOpen) return;
    if (this.shadowRoot?.contains(event.target as Node)) return;
    if (event.composedPath().includes(this)) return;
    this._isOpen = false;
  };

  private parseData(): void {
    this._parsedData = this.data?.items?.reduce((result, item) => {
      const { category, value, label, subLabel } = item;
      const categoryKey = category ?? this._UNCATEGORIZED;

      if (!result[categoryKey]) {
        result[categoryKey] = [];
      }

      result[categoryKey].push({ value, label, subLabel });

      return result;
    }, {} as ParsedData);
  }

  private handleSelect(event: MouseEvent | KeyboardEvent): void {
    if (event instanceof KeyboardEvent && event.key !== 'Enter') return;
    
    this._isOpen = false;
    const target = event.currentTarget as HTMLElement;
    const value = target.getAttribute('data-value');
    this.dispatchEvent(new CustomEvent('onSelectItem', { detail: value }));
  }

  protected firstUpdated(): void {
    this.checkDropup();
  }

  protected updated(_changedProperties: PropertyValues): void {
    if (_changedProperties.has('data')) {
      this.parseData();
      this.checkDropup();
    }
  }

  render() {
    if (!this.data) return html `<slot></slot>`;

    const { placeholder, noResultMessage } = this.data;
    
    return html`
      <slot @click=${() => this._isOpen = true}></slot>
      ${this._parsedData ? html `
        <section 
          class=${classMap({
            'aurora-dropdown': true,
            'aurora-dropdown--dropup': this._dropup,
          })}>
          <aurora-scroll>
            <div
              class=${classMap({
                'dropdown-container': true,
                'dropdown-container--open': this._isOpen,
              })}
            >
              ${!isObjectEmpty(this._parsedData)
                ? html`
                  ${placeholder ? html `<p class="dropdown-placeholder">${placeholder}</p>` : nothing}
                  ${Object.entries(this._parsedData).map(([category, items]) => html`
                    <div class="dropdown-category">
                      ${category != this._UNCATEGORIZED
                        ? html `<p id=${category} class="dropdown-category-title">${category} (${items.length})</p>`
                        : nothing}
                      <ul class="dropdown-category-list" aria-labelledby=${category}>
                        ${items.map((item: IDropdownItem) => html `
                          <li 
                            class="dropdown-category-listitem"
                            data-value=${item.value}
                            tabindex="0"
                            @click=${this.handleSelect}
                            @keydown=${this.handleSelect}>
                              <span class="dropdown-category-label" .innerHTML=${domPurify(item.label)}></span>
                              ${item.subLabel ? html `<span class="dropdown-category-sublabel">${item.subLabel}</span>` : nothing}
                          </li>
                        `)}
                      </ul>
                    </div>  
                  `)}`
                : html `
                  ${noResultMessage ? html`
                    <div class="dropdown-category dropdown-category--no-result">
                      <span class="dropdown-category-title">${noResultMessage.placeholder}</span>
                      <span class="dropdown-category-label">${noResultMessage.label}</span>
                    </div>
                  `: nothing}
                `}
            </div>
          </aurora-scroll>
        </section>`
      : nothing}
      `
  }
}
