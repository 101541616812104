/* eslint-disable no-useless-escape */
const setCookie = (name: string, value: string, options: Record<string, any>) => {
  const newOptions: Record<string, string> = {
    path: '/',
    ...options,
  };

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in newOptions) {
    updatedCookie += '; ' + optionKey;
    const optionValue = newOptions[optionKey];
    if (optionValue) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

const getLocationCookie = () => {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + 'userGeoLocation'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : null;
};

const deleteLocationCookie = () => {
  setCookie('userGeoLocation', '', {
    'max-age': -1,
  });
};

const saveLocationCookie = (latLng: string) => {
  try {
    const date = new Date();
    date.setTime(date.getTime() + 400 * 24 * 60 * 60 * 1000); // here 400 is days
    const expires = `expires=${date.toUTCString()}`;
    setCookie('userGeoLocation', latLng, {
      secure: true,
      expires,
    });
  } catch (err) {
    console.log('cookie error', err);
  }
};

export { saveLocationCookie, deleteLocationCookie, getLocationCookie };
