// Created By: 

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';

// styles
import styles from './aurora-unified-search-hero-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

// components
import '../../aurora-select/index';
import '../../aurora-location-selector/index';
import '../../aurora-input/index';
import '../../aurora-date-input/index';
import '../../aurora-search-button/index';
import '../../aurora-unified-search-autocomplete/index';

// utils
import { formatDate } from '../../utils/date';

export class AuroraUnifiedSearchHero extends LitElement {
  @property({ type: Object })
  data: {
    title: string;
    hideDatepicker?: boolean;
    searchHref?: string;
    input?: {
      placeholder: string;
    };
    dateInput?: {
      placeholder: string;
    };
    select?: {
      placeholder: string;
      options: {
        value: string|number;
        label: string;
        hideDatepicker?: boolean;
        url?: string;
      }[];
    };
  }

  @property({ type: Boolean, attribute: 'hide' })
  _hide = false;

  @property({ type: Object, attribute: false })
  _form = {
    selectValue: '',
    inputValue: '',
    dateValue: {
      startDate: null,
      endDate: null,
    } as Record<string, Date>
  }

  static get styles() {
    return [styles, global, typography];
  }

  connectedCallback() {
    super.connectedCallback();

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('hide-aurora-unified-search')) {
      this._hide = true;
    }
    
    // unified search is a collection of isolated components, and some of them need to comunicate between
    // for such cases we use the event system
    window.addEventListener('unified-search', this.onUnifiedSearch);

    // we need to inform asap the other unified-search components the 'hero' exists and is here
    // we emit a event for such
    const detail = {
      sender: 'hero', // the sender is the hero
      id: Math.random().toString(36).substring(7), // a random id to track the request
      hero: { // the hero is information
        present: true
      }
    };

    window.dispatchEvent(new CustomEvent('unified-search', { detail }));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  onUnifiedSearch = (e: CustomEvent) => {
    // other unified search components can request information and send theirs, we need to handle such
    if (!e.detail) return;
    const { type, sender, id } = e.detail;
    // ignore our one
    if (sender === 'hero') return;
    // ignore all responses to requests
    if (type === 'response') return;

    // we need to inform the other unified-search components the 'hero' exists and is here
    const detail = {
      sender: 'hero',
      id,
      type: 'response',
      hero: {
        present: true
      }
    };

    window.dispatchEvent(new CustomEvent('unified-search', { detail }));
  }
  
  onValue = (e: CustomEvent, key: string) => {
    if (!(key in this._form)) return;
    this._form[key as keyof typeof this._form] = e.detail;

    if (key === 'selectValue') {
      const selectOption = this.data?.select?.options.find(o => o.value === e.detail);
      if (selectOption?.hideDatepicker) this._form.dateValue = {};
    }
    
    this.dispatchEvent(new CustomEvent(key, e));
    this.requestUpdate();
  }

  onSearch() {
    const url = new URL(this.data?.searchHref || window.location.href);

    // Set 'query' parameters
    // Todo: add proper url handling. This is for alpha testing only
    ['keyword', 'query', 'q'].forEach(param => {
      if (url.toString().includes(`${param}=`)) {
        url.searchParams.set(param, this._form.inputValue);
      }
    });

    // Set date parameters if present
    const { startDate, endDate } = this._form.dateValue;
    if (startDate) {
      url.searchParams.set('startDate', formatDate(startDate));
    }
    if (endDate) {
      url.searchParams.set('endDate', formatDate(endDate));
    }

    // Redirect to the updated URL
    if (window.location.host.includes('novanthealth.org')) {
      window.location.href = url.toString();
    } else {
      window.open(url.toString(), '_self');
    }
  }

  render() {
    if (this._hide) {
      return nothing;
    }

    const { title, hideDatepicker, select, input, dateInput } = this.data || {};

    return html`
      <section class="aurora-unified-search-hero">
        <div class="aurora-unified-search-hero_title">
          ${title}
        </div>
        
        <div class="aurora-unified-search-hero_zipcode">
          <aurora-location-selector></aurora-location-selector>
        </div>

        <div class="aurora-unified-search-hero_select">
          <aurora-select 
            .data=${{
              placeholder: select?.placeholder || '',
              value: this._form.selectValue || '',
              options: select?.options || []
            }}
            @value=${(e: CustomEvent) => this.onValue(e, 'selectValue')}
          ></aurora-select>
        </div>

        <div class="aurora-unified-search-hero_input">
          <aurora-unified-search-autocomplete
            .data=${{
              placeholder: 'Select an item from the list below.',
              inputPlaceHolder: input?.placeholder || '',
              inputValue: this._form.inputValue || '',
              selectValue: this._form.selectValue || '',
            }}
            @value=${(e: CustomEvent) => this.onValue(e, 'inputValue')}
          ></aurora-unified-search-autocomplete>
        </div>
        
        ${hideDatepicker ? nothing : html`
          <div class="aurora-unified-search-hero_datepicker">
            <aurora-date-input
              .data=${{
                placeholder: dateInput?.placeholder || '',
                value: this._form.dateValue || null,
                useDaterange: true
              }}
              @value=${(e: CustomEvent) => this.onValue(e, 'dateValue')}
            ></aurora-date-input>
          </div>
        `}
        
        <div class="aurora-unified-search-hero_btn">
          <aurora-search-button 
            .data=${{
              title: 'Search',
              icon: true
            }}
            @click=${this.onSearch}
          ></aurora-search-button>
        </div>

      </section>`
  }
}
