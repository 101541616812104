// Created By: Mateo Trujillo

import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import { 
	constellationScrollTrigger,
	constellationTimelineFrom,
	constellationTimelineTo,
	contentScrollTrigger,
	contentTimelineFrom,
	contentTimelineTo,
	LottieIconsScrollTrigger
} from '../../utils/gsap-utils';
import gsap from 'gsap';
import lottieWeb from 'lottie-web';

// styles
import styles from './aurora-accessibility-panel-css';
import homepanelStyles from '../../aurora-homepage-panel/src/aurora-homepage-panel-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

//types
import { IAuroraPanel } from '../../types/types';

export class AuroraAccessibilityPanel extends LitElement {
  @property({ type: Object, attribute: false })
  data: IAuroraPanel;

  @query('#accessibility-panel') private accessibilityPanel: HTMLElement;
  @query('#constellation') private constellation: HTMLDivElement;
  @query('#accessibility-icon') private accessibilityIcon: HTMLDivElement;
  @query('#accessibility-text') private accessibilityText: HTMLDivElement;
  @query('#accessibility-title') private accessibilityTitle: HTMLDivElement;
  @query('#accessibility-description') private accessibilityDescription: HTMLDivElement;

  private _isDesktop: boolean = window.innerWidth > 992;

  static get styles() {
    return [homepanelStyles, styles, global, typography];
  }

  protected updated(): void {
    this.loadAnimations();
  }

  connectedCallback() {
    super.connectedCallback();

    const attr = this.getAttribute('data');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.data = JSON.parse(this.getAttribute('data'));
      this.setAttribute('data', '{"loaded": true}');
    }
  }

  render() {
    const { title, description, constellationSrc } = this.data;

    return html` 
      <aurora-homepage-panel>
        <section id="accessibility-panel" class="homepage-panel accessibility">
          <div class="section-constellation-wrapper">
            <div class="section-lottie">
              <div id="constellation" class="constellation constellation-accessibility">
                <img src="${constellationSrc}" alt="animation" />
              </div>
              <div id="accessibility-icon" class="icon-animation icon-accessibility"></div>
            </div>
          </div>
          <div id="accessibility-text" class="section-content-wrapper accessibility-text">
            <div class="content-title" id="accessibility-title">
              <h2>${title}</h2>
            </div>
            <div id="accessibility-description">
              <p class="body-1">${description}</p>
            </div>
          </div>
        </section>
      </aurora-homepage-panel>
    `;
  }

  private loadAnimations(): void {
    gsap.registerPlugin(ScrollTrigger);

    gsap
			.timeline({
				scrollTrigger: {
					...constellationScrollTrigger,
					trigger: this.accessibilityPanel
				}
			})
			.from(this.constellation, { ...constellationTimelineFrom, rotation: -79 })
			.to(this.constellation, constellationTimelineTo);

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.accessibilityText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.accessibilityTitle, {	...contentTimelineFrom,	x: 100	})
			.to(this.accessibilityTitle, {	...contentTimelineTo,	x: 100 });

		gsap
			.timeline({ 
				scrollTrigger: {
					...contentScrollTrigger,
					trigger: this.accessibilityText,
					start: this._isDesktop ? '10% center' : '10% bottom'
				}
			})
			.from(this.accessibilityDescription, {	...contentTimelineFrom,	y: 100 })
			.to(this.accessibilityDescription, {	...contentTimelineTo,	y: 100 });

    LottieIconsScrollTrigger({
			rendererSettings: { id: 'accessibility-lottie' },
			target: this.accessibilityIcon,
			path: this.data.animationSrc,
		}, gsap, lottieWeb, ScrollTrigger);
  }
}
