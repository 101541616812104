/* eslint-disable no-unused-expressions */

export const constellationScrollTrigger = {
	pin: false,
	start: '10% center', 
	end: '200% top', 
	scrub: 1, 
}

export const constellationTimelineFrom = {
	scale: 3,
	rotation: -36,
	autoAlpha: 0,
	duration: 1.5
}

export const constellationTimelineTo = {
	scale: 2,
	rotation: 36,
	autoAlpha: 0,
	duration: 2,
	delay: 4
}

export const contentScrollTrigger = {
	pin: false,
	end: '200% top',
	scrub: 1,
}

export const contentTimelineFrom = {
	autoAlpha: 0,
	duration: 4,
	delay: 8
}

export const contentTimelineTo = {
	autoAlpha: 0,
	duration: 6,
	delay: 14,
}

export function LottieIconsScrollTrigger(vars, gsap, lottie, ScrollTrigger) {
	const target = gsap.utils.toArray(vars.target)[0];
	const targetId = vars.rendererSettings.id;
	
	const existingLottie = target.querySelector(`#${targetId}`);
	if (existingLottie) {
		return;
	}

	const playhead = { frame: 0 };
	const st = {
		markers: false,
		trigger: target,
		pin: false,
		start: '90% center',
		end: vars.isDesktop ? '+=1800' : '700% top',
		scrub: 1,
	};
        
	const ctx = gsap.context && gsap.context();

	const animation = lottie.loadAnimation({
		container: target,
		renderer: vars.renderer || 'svg',
		loop: false,
		autoplay: false,
		path: vars.path,
		rendererSettings: vars.rendererSettings || { preserveAspectRatio: 'xMidYMid slice' }
	});

	for (const p in vars) { // let users override the ScrollTrigger defaults
		st[p] = vars[p];
	}

	animation.addEventListener('DOMLoaded', () => {
		const tl = gsap.timeline({
			onUpdate: () => animation.goToAndStop(playhead.frame, true),
			scrollTrigger: st
		});

		tl.from(playhead, {
			frame: 0,
			delay: 1,
		}).to(playhead, {
			frame: animation.totalFrames - 1,
			duration: 12,
			delay: 2,
		}).to(playhead, {
			frame: 0,
			delay: 20,
			duration: 12,
		});
		
		const createTween = () => {
			animation.frameTween = tl;
			return () => animation.destroy && animation.destroy();
		};

		ctx && ctx.add ? ctx.add(createTween) : createTween();
		// in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
		ScrollTrigger.sort();
		ScrollTrigger.refresh();
	});
	
	return animation;
}