// Created By: Jackeline Mendoza

import { html, LitElement } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';


// styles
import styles from './aurora-tertiary-sub-nav-item-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export class AuroraTertiarySubNavItem extends LitElement {
  @property({ type: Object })
  data: {
    title?: string;
    active?: boolean;
  }
  
  @state() _loading: boolean;

  private handleSubNavClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.dispatchEvent(new CustomEvent('click', e));
  };

  static get styles() {
    return [styles, global, typography];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const { title } = this.data;

    return html`
            <button @click=${this.handleSubNavClick} class=${classMap({
          'aurora-tertiary-sub-nav-item' : true, 'active': this.data.active
        })}>${title}</button>
         `
  }
}
