// Created By: Mateo Trujillo
import { html, LitElement, nothing, PropertyValues } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Components
import '../../aurora-base-carousel/index';
import '../../aurora-tertiary-nav-item/index';
import '../../aurora-tertiary-sub-nav-item/index';

// styles
import styles from './aurora-tertiary-nav-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

type IAuroraTertiaryNavItems = {
  title?: string,
  active?: boolean,
  subNavItems?: IAuroraTertiarySubNavItems[];
};

type IAuroraTertiarySubNavItems = {
  title?: string,
  active?: boolean,
  contentType?: string
};

export class AuroraTertiaryNav extends LitElement {
  @state() private _offset = 0;
  @state() private _isCarouselEnd = false;
  @state() private _isNavOpen = false; 
  @state() private _isPanelOpen = false;
  _openSubNavIndex: number | null = null;
  @state() private _isCarouselDisabled: boolean;

  @query('#top-nav-base-carousel') private carouselElement?: HTMLElement;
  @query('#sub-nav-base-carousel') private subNavCarouselElement?: HTMLElement;

  @property({ type: Object })
  data: {
    navItems?: IAuroraTertiaryNavItems[];
  }

  static get styles() {
    return [styles, global, typography];
  }

  updateValues(e: CustomEvent): void {
    this._offset = e.detail.offset;
    this._isCarouselEnd = e.detail.isCarouselEnd;
  }

  checkCarouselDisabled(event: CustomEvent): void {
    this._isCarouselDisabled = event.detail.isCarouselDisabled;
  }

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);

    const { hash: itemIdHash } = window.location;
    if (_changedProperties.has('data') && !!itemIdHash) {
      this._isNavOpen = this.data.navItems.some((navItem) => 
        navItem.subNavItems?.some(subNavItem => subNavItem.active)
      );
    }
  }

  onClick(item: IAuroraTertiaryNavItems, index: number) {
    this.dispatchEvent(new CustomEvent('navClick', { detail: { item, index } }));
    this.subNavCarouselElement.dispatchEvent(new CustomEvent('resetCarousel'));

    const hasSubNav = Boolean(item.subNavItems && item.subNavItems.length > 0);
    this._openSubNavIndex = this._openSubNavIndex === index ? null : index;
    this._isNavOpen = hasSubNav;
  }

  onSubNavClick(subItem: IAuroraTertiarySubNavItems, subIndex: number, item: IAuroraTertiaryNavItems, index: number) {
    this.dispatchEvent(new CustomEvent('subNavClick', { detail: { subItem, subIndex, item, index } }));
  }

  handleClick(event: MouseEvent) {
    event.stopPropagation();
    this._isPanelOpen = !this._isPanelOpen;
  }

  get caretIcon() {
    return html`
    <span class="caret">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 8L12 15L19 8" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
    `
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    if (!this.data) return nothing;
    const { navItems } = this.data;

    const tertiaryNavItems = navItems.slice(0, 10);

    return html`
      <nav class=${classMap({
        "aurora-tertiary-nav": true,
        "open": this._isNavOpen,
      })}>

        <!-- desktop view -->
        
        <div 
          id="top-nav-container" 
          class=${classMap({
            "hide-tablet": true,
            "top-nav-container left-shadow right-shadow": true,
            "left-shadow__active": this._offset > 0,
            "right-shadow__active": !this._isCarouselEnd
          })}
        >
          <aurora-base-carousel 
            id="top-nav-base-carousel"
            @sync-values="${this.updateValues}"
            @carousel-initialized=${this.checkCarouselDisabled}
            .data=${{
              direction: 'horizontal',
              gutter: false,
              edgeBounce: false,
            }}
          >
            ${tertiaryNavItems.map((item: IAuroraTertiaryNavItems, index: number) => html`
              <div id="tertiary-nav-items">
                <aurora-tertiary-nav-item
                  @click="${() => this.onClick(item, index)}"
                  .data=${{
                    "title": item.title,
                    "active": item.active
                  }}
                >
                </aurora-tertiary-nav-item>
              </div>    
            `)}
          </aurora-base-carousel>
          
          <button 
            ?disabled=${this._offset <= 0} 
            aria-label="previous-button"
            class=${classMap({
              'prev-btn': true,
              'prev-btn--hidden': this._isCarouselDisabled
            })}
            @click="${() => this.carouselElement.dispatchEvent(new CustomEvent('movePrevious'))}"
          >
            ${this.caretIcon}
          </button>
          
          <button 
            ?disabled=${this._isCarouselEnd} 
            aria-label="next-button"
            class=${classMap({
              'next-btn': true,
              'next-btn--hidden': this._isCarouselDisabled
            })}
            @click="${() => this.carouselElement.dispatchEvent(new CustomEvent('moveNext'))}"
          >
            ${this.caretIcon}
          </button>
        </div>
        
        <div
          class=${classMap({
            "sub-nav": true,
            "hide-tablet": true,
            "open": this._isNavOpen,
          })}
        >

        <aurora-base-carousel 
          id="sub-nav-base-carousel"
          .data=${{
            direction: 'horizontal',
            gutter: false,
            edgeBounce: false,
          }}
        > 
          ${tertiaryNavItems.map((item: IAuroraTertiaryNavItems, index) => html`
            ${item.active && item.subNavItems && item.subNavItems.length > 0 ? html`
                ${item.subNavItems?.slice(0, 6).map((subItem: IAuroraTertiarySubNavItems, subIndex: number) => html`
                  <aurora-tertiary-sub-nav-item id="${subIndex}"
                    @click="${() => this.onSubNavClick(subItem, subIndex, item, index)}"
                    .data=${{
                      title: subItem.title,
                      active: subItem.active,
                    }}
                  ></aurora-tertiary-sub-nav-item>
                `)}
                
            ` : nothing}
          `)}
        </aurora-base-carousel> 
      </div>

      <!-- mobile view -->

      <button class=${
        classMap({ 
          "top-btn hide-desktop": true, 
          "open": this._isPanelOpen 
        })} 
        @click="${this.handleClick}"
      >
        selected page
        <span class="arrow">
          ${this.caretIcon}
        </span>
      </button>

        <div 
          class="${classMap({
            'top-nav-container hide-desktop mobile': true,
            'open': this._isPanelOpen,
          })}"
        >
          ${tertiaryNavItems.map((item: IAuroraTertiaryNavItems, index: number) => html`
            <div
              class="items-container"
              id="tertiary-nav-items-${index}"
            >
              <aurora-tertiary-nav-item
                @click="${() => this.onClick(item, index)}"
                .data=${{
                  "title": item.title,
                  "active": item.active
                }}
              ></aurora-tertiary-nav-item>
              
              <div
                class="${classMap({
                  "sub-nav-mobile": true,
                  "hide-desktop": true,
                  "open": this._openSubNavIndex === index // Only open if this item's index matches _openSubNavIndex
                })}"
              >
                ${item.active && item.subNavItems && item.subNavItems.length > 0
                  ? item.subNavItems?.slice(0, 6).map((subItem: IAuroraTertiarySubNavItems, subIndex: number) => html`
                      <aurora-tertiary-sub-nav-item
                        id="tertiary-sub-nav-item-mobile-${subIndex}"
                        @click="${() => this.onSubNavClick(subItem, subIndex, item, index)}"
                        .data=${{
                          title: subItem.title,
                          active: subItem.active,
                        }}
                      ></aurora-tertiary-sub-nav-item>
                    `)
                  : nothing
                }
              </div>  
            </div>    
          `)}   
        </div>
    </nav>`
  }
}