// Created By: artur

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-unified-search-top-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

// components
import '../../aurora-select/index';
import '../../aurora-location-selector/index';
import '../../aurora-input/index';
import '../../aurora-date-input/index';
import '../../aurora-search-button/index';
import '../../aurora-unified-search-autocomplete/index';

// utils
import { formatDate } from '../../utils/date';

export class AuroraUnifiedSearchTop extends LitElement {
  @property({ type: Object })
  data: {
    hideDatepicker?: boolean;
    searchHref?: string;
    input?: {
      placeholder: string;
    };
    dateInput?: {
      placeholder: string;
    };
    select?: {
      placeholder: string;
      options: {
        value: string | number;
        label: string;
        hideDatepicker?: boolean;
        url?: string;
      }[];
    };
  }

  @property({ type: Boolean, attribute: 'hide' })
  _hide = false;

  @property({ type: Boolean, attribute: 'noUnifiedHeroSearchPresent' })
  _noUnifiedHeroSearchPresent = true;

  @property({ type: Object, attribute: false })
  _form = {
    selectValue: '',
    inputValue: '',
    dateValue: {
      startDate: null,
      endDate: null,
    } as Record<string, Date>
  }

  static get styles() {
    return [styles, global, typography];
  }

  connectedCallback() {
    super.connectedCallback();

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('hide-aurora-unified-search')) {
      this._hide = true;
    }

    // we need to know if the aurora-unified-search-hero is NOT present on the page, and so, force show the aurora-unified-search-top
    // for such, we do two things.
    // first we create a comunication based on events, and start listening for them,
    // is expected the 'hero' to send a event asap, and we will hear it.
    // but sometime it may be emitted before we create the listener on this side.
    // and because of that, we use the second approach.
    // we actively ask for the 'hero' to send us a event.

    // this is the first option, we listen for the event
    window.addEventListener('unified-search', this.onUnifiedSearch);

    // this is the second option, we ask for the event
    this.checkUnifiedHeroSearch();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('unified-search', this.onUnifiedSearch);
  }
  
  onUnifiedSearch = (e: CustomEvent) => {
    if (!e.detail) return
    if (e.detail.sender === 'top') return
    if (e.detail.type === 'response') return
    // we should ignore all the events which are:
    // from ourselfes,
    // which are responses for requests
    // them, in the payload, we check for the presence of the information to inform if the aurora-unified-search-hero is present or not

    this._noUnifiedHeroSearchPresent = !e.detail?.hero?.present
  }

  checkUnifiedHeroSearch() {
    // events are asyncm and may never happen, so we need to handle it, the wrap of a promise is to make it easier to handle
    return new Promise((resolve, reject) => {
      // events are hard to track, se we add some informations to track what event is what
      const detail = { 
        sender: 'top', // who is sending the event
        id: Math.random().toString(36).substring(7), // a random id to track the event and send a response
        type: 'request', // if is a request or response
        request: 'hero' // to who is the request
      }
  
      // as it may never happen, we add a timeout to handle it
      const timeoutId = setTimeout(() => {
        window.removeEventListener('unified-search', responseFunction);
        reject('timeout')
      }, 10_000);
  
      // the handler for the response
      const responseFunction = (e: CustomEvent) => {
        if (!e.detail) return
        const {sender, type, id, hero} = e.detail
        if (sender !== 'hero') return // we only want a response from the hero
        if (type !== 'response') return // we only want a response
        if (id !== detail.id) return // we only want the response for the request we made
  
        this._noUnifiedHeroSearchPresent = !hero?.present
        window.removeEventListener('unified-search', responseFunction);
        resolve(this._noUnifiedHeroSearchPresent) // we got the response, so we resolve the promise
        clearTimeout(timeoutId); // we clear the timeout
      }
  
      window.addEventListener('unified-search', responseFunction); // as this is a event, we need to add a event listener
      window.dispatchEvent(new CustomEvent('unified-search', { detail })); // we send the request
    })
  }
  
  onValue = (e: CustomEvent, key: string) => {
    if (!(key in this._form)) return;
    this._form[key as keyof typeof this._form] = e.detail;
    
    if (key === 'selectValue') {
      const selectOption = this.data?.select?.options.find(o => o.value === e.detail);
      if (selectOption?.hideDatepicker) this._form.dateValue = {};
    }

    this.dispatchEvent(new CustomEvent(key, e));
    this.requestUpdate();
  }

  onSearch() {
    const url = new URL(this.data?.searchHref || window.location.href);

    // Set 'query' parameters
    // Todo: add proper url handling. This is for alpha testing only
    ['keyword', 'query', 'q'].forEach(param => {
      if (url.toString().includes(`${param}=`)) {
        url.searchParams.set(param, this._form.inputValue);
      }
    });

    // Set date parameters if present
    const { startDate, endDate } = this._form.dateValue;
    if (startDate) url.searchParams.set('startDate', formatDate(startDate));
    if (endDate) url.searchParams.set('endDate', formatDate(endDate));

    // Redirect to the updated URL
    if (window.location.host.includes('novanthealth.org')) {
      window.location.href = url.toString();
    } else {
      window.open(url.toString(), '_self');
    }
  }

  render() {
    if (this._hide) return nothing;

    const { hideDatepicker, select, input, dateInput } = this.data || {};

    return html`
      <section
        class="${classMap({
          'aurora-unified-search-top': true,
          'aurora-unified-search-top_no-hero': this._noUnifiedHeroSearchPresent
        })}"
      >
        <div class="aurora-unified-search-top_content">
          <div class="aurora-unified-search-top_zipcode">
            <aurora-location-selector></aurora-location-selector>
          </div>

          <div class="aurora-unified-search-top_select">
            <aurora-select 
              .data=${{
                placeholder: select?.placeholder || '',
                value: this._form.selectValue || '',
                options: select?.options || []
              }}
              @value=${(e: CustomEvent) => this.onValue(e, 'selectValue')}
            ></aurora-select>
          </div>

          <div class="aurora-unified-search-top_input">
            <aurora-unified-search-autocomplete
              .data=${{
                placeholder: 'Select an item from the list below.',
                inputPlaceHolder: input?.placeholder || '',
                inputValue: this._form.inputValue || '',
                selectValue: this._form.selectValue || '',
              }}
              @value=${(e: CustomEvent) => this.onValue(e, 'inputValue')}
            ></aurora-unified-search-autocomplete>
          </div>

          ${hideDatepicker ? nothing : html`
            <div class="aurora-unified-search-top_datepicker">
              <aurora-date-input
                .data=${{
                  placeholder: dateInput?.placeholder || '',
                  value: this._form.dateValue || null,
                  useDaterange: true
                }}
                @value=${(e: CustomEvent) => this.onValue(e, 'dateValue')}
              ></aurora-date-input>
            </div>
          `}
          
          <div class="aurora-unified-search-top_btn">
            <aurora-search-button 
              .data=${{
                title: 'Search',
                icon: true
              }}
              @click=${this.onSearch}
            ></aurora-search-button>
          </div>
        </div>
      </section>`
  }
}
